.upper {
  overflow-y: auto;
  margin-top: 20px;
  padding-left: 0px;
  padding-right: 0px;
  display: flex;
  flex-direction: column-reverse;
}

.lower {
  width: 100%;
  padding-bottom: 20px;
  padding-left: 12px;
  padding-right: 8px;
}

.comment-section {
  border: none;
  border-radius: 6px !important;

  font-style: normal;
  font-weight: normal;
  color: #374151;
}

.editor-wrapper {
  padding-top: 0px;
  width: 100%;
  /* position: absolute;
  bottom: 10px;
  width: 80%; */
}

.avartar-wrapper {
  display: inline-block !important;
  padding-left: 0px;
}

.ant-row-editor {
  flex-flow: nowrap !important;
  height: 256px;
}

.lower-container {
  /* border: 1px solid #d1d5db; */
  border-radius: 6px;
  font-style: normal;
  font-weight: normal;
  line-height: 15px;
  color: #374151;
}

.comment-button-row {
  width: "100%";
  text-align: center;
}

.quill-holder :global .ql-editor {
  min-height: 100px;
  height: fit-content;
  max-height: 300px;
  overflow: auto;
}
