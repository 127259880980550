.avatar {
  opacity: 1;
  transition-timing-function: ease;
  transition-property: opacity;
  transition-duration: 0.2s;
  cursor: pointer;
}

.avatar.disabled {
  opacity: 0.3;
  cursor: not-allowed;
}
