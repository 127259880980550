@import "./antd.css";

/* Change autofill styles in WebKit */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--portal-form-input-text-color);
  transition: background-color 5000s ease-in-out 0s;
}

body {
  margin: 0;
  overflow-x: hidden;
}

* {
  font-family: "Inter", Sans-Serif;
}

/*
  this is here because bootstrap sets it to middle, which screws up the alignment of all our icons
  if we don't import bootstrap in our _app.js file anymore, we should remove this
*/
img,
svg {
  vertical-align: baseline !important;
}
.light {
  transition: background-color 0.3s ease;

  /* Theme variables */
  --app-entry-layout-bg: #ffffff;
  --portal-title-color: #262626;
  --portal-form-title-color: #364559;
  --portal-form-input-border: 1px solid #4d6380;
  --portal-form-input-bg-color: rgba(255, 255, 255, 0);
  --portal-form-input-text-color: #364559;
  --portal-form-button-text-color: #ffffff;
  --portal-form-button-bg: #364559;
  --portal-form-button-border: 1px solid #364559;
  --portal-form-or-row-color: #364559;
  --portal-form-footnote-left-color: #364559;
  --portal-form-footnote-right-color: #cf9734;
  --portal-form-privacy-policy-left-text-color: black;
  --portal-form-tooltip-text-color: rgba(0, 0, 0, 0.85);
}

.dark {
  transition: background-color 0.3s ease;

  /* Theme variables */
  --app-entry-layout-bg: linear-gradient(180deg, #364559 0%, #182029 100%);
  --portal-title-color: #ffffff;
  --portal-form-title-color: #ffffff;
  --portal-form-input-border: 0;
  --portal-form-input-bg-color: rgba(77, 99, 128, 0.3);
  --portal-form-input-text-color: rgba(255, 255, 255, 0.7);
  --portal-form-button-text-color: #364559;
  --portal-form-button-bg: linear-gradient(90deg, #9bcfff -18.26%, #8897cb 150.45%);
  --portal-form-button-border: 1px solid #364559;
  --portal-form-or-row-color: #ffffff;
  --portal-form-footnote-left-color: #4d6380;
  --portal-form-footnote-right-color: #ffffff;
  --portal-form-privacy-policy-left-text-color: white;
  --portal-form-tooltip-text-color: white;
}

.centered-container {
  width: fit-content;
  margin: 0 auto;
  text-align: center;
}

.see-more-btn {
  border-radius: 10px;
  color: #1890ff;
  padding: 7px 16px;
  height: fit-content;
  margin-top: 5px;
  width: 150px;
}

@media (max-width: 767px) {
  .centered-container {
    width: fit-content;
  }
}

.ql-editor > * {
  word-break: break-word;
  overflow-wrap: break-word;
}

.react-pdf__Page {
  margin-top: 0px;
  background-color: #eeeeee;
}

.react-pdf__Page__textContent {
  border: 1px solid darkgrey;
  box-shadow: 5px 5px 5px 1px #ccc;
  border-radius: 5px;
  overflow: -moz-hidden-unscrollable;
}

.react-pdf__Page__annotations.annotationLayer {
  padding: 20px;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
  overflow: hidden;
}

.ql-mention-list-container {
  max-height: 200px;
  width: 330px;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 2px 12px 0 rgba(30, 30, 30, 0.08);
  z-index: 9001;
  overflow: auto;
}

.ql-mention-loading {
  line-height: 44px;
  padding: 0 20px;
  vertical-align: middle;
  font-size: 16px;
}

.ql-mention-list {
  list-style: none;
  margin: 0;
  padding: 0;
  /* overflow: hidden; */
  overflow: auto;
}

.ql-mention-list-item {
  cursor: pointer;
  padding: 0 20px;
  vertical-align: middle;

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 33px;
  display: flex;
  align-items: center;

  color: #595959;
}

.ql-mention-list-item.disabled {
  cursor: auto;
}

.ql-mention-list-item.selected {
  /* background-color: #d3e1eb; */
  background-color: rgba(70, 145, 226, 0.34);

  text-decoration: none;
}

.mention {
  height: 24px;
  width: 65px;
  border-radius: 6px;
  /* background-color: rgba(70, 145, 226, 0.34); */

  padding: 3px 0;
  margin-right: 2px;
  user-select: all;
}

.mention > span {
  margin: 0 3px;
}

/**
 * 1. Change the font styles in all browsers.
 * 2. Remove the margin in Firefox and Safari.
 */

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
}

/**
  * Show the overflow in IE.
  * 1. Show the overflow in Edge.
  */

button,
input {
  /* 1 */
  overflow: visible;
}

/**
  * Remove the inheritance of text transform in Edge, Firefox, and IE.
  * 1. Remove the inheritance of text transform in Firefox.
  */

button,
select {
  /* 1 */
  text-transform: none;
}

/**
  * Correct the inability to style clickable types in iOS and Safari.
  */

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button,
input[type="submit"],
input[type="reset"] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.recharts-tooltip-wrapper {
  z-index: 1000 !important;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --color-n-0: 255 255 255;
    --color-n-5: 243 243 244;
    --color-n-10: 232 232 233;
    --color-n-20: 209 209 211;
    --color-n-30: 186 186 189;
    --color-n-40: 162 163 166;
    --color-n-50: 139 140 144;
    --color-n-60: 116 117 122;
    --color-n-70: 93 94 100;
    --color-n-80: 69 71 77;
    --color-n-90: 47 48 56;
    --color-n-95: 35 37 44;
    --color-n-100: 23 25 33;
    --ui-navigation-bg-header: var(--primitive-alpha-white-80);
    --tw-white: 255 255 255;
    --tw-black: 0 0 0;
    --transparent: rgb(255 255 255 / 0);
    --primitive-primary-brand-25: 254 250 250;
    --primitive-primary-brand-50: 253 244 244;
    --primitive-primary-brand-100: 250 224 222;
    --primitive-primary-brand-200: 248 204 201;
    --primitive-primary-brand-300: 242 162 158;
    --primitive-primary-brand-400: 235 114 107;
    --primitive-primary-brand-500: 229 69 61;
    --primitive-primary-brand-600: 202 61 53;
    --primitive-primary-brand-700: 174 53 45;
    --primitive-primary-brand-800: 141 42 37;
    --primitive-primary-brand-900: 118 35 30;
    --primitive-primary-brand-950: 81 25 21;
    --primitive-primary-grey-25: 252 253 253;
    --primitive-primary-grey-50: 247 248 248;
    --primitive-primary-grey-100: 241 243 243;
    --primitive-primary-grey-200: 229 230 230;
    --primitive-primary-grey-300: 207 209 211;
    --primitive-primary-grey-400: 160 161 166;
    --primitive-primary-grey-500: 126 128 134;
    --primitive-primary-grey-600: 100 102 109;
    --primitive-primary-grey-700: 55 56 62;
    --primitive-primary-grey-800: 39 40 47;
    --primitive-primary-grey-900: 23 25 33;
    --primitive-primary-grey-950: 13 14 18;
    --primitive-primary-success-25: 239 251 247;
    --primitive-primary-success-50: 229 245 238;
    --primitive-primary-success-100: 210 238 226;
    --primitive-primary-success-200: 170 223 200;
    --primitive-primary-success-300: 125 206 172;
    --primitive-primary-success-400: 88 192 148;
    --primitive-primary-success-500: 59 170 122;
    --primitive-primary-success-600: 48 140 100;
    --primitive-primary-success-700: 40 113 82;
    --primitive-primary-success-800: 33 95 68;
    --primitive-primary-success-900: 26 76 55;
    --primitive-primary-success-950: 18 54 40;
    --primitive-primary-error-25: 254 250 250;
    --primitive-primary-error-50: 253 244 244;
    --primitive-primary-error-100: 250 224 222;
    --primitive-primary-error-200: 248 204 201;
    --primitive-primary-error-300: 242 162 158;
    --primitive-primary-error-400: 235 114 107;
    --primitive-primary-error-500: 229 69 61;
    --primitive-primary-error-600: 202 61 53;
    --primitive-primary-error-700: 174 53 45;
    --primitive-primary-error-800: 141 42 37;
    --primitive-primary-error-900: 118 35 30;
    --primitive-primary-error-950: 81 25 21;
    --primitive-primary-warning-25: 254 253 251;
    --primitive-primary-warning-50: 253 249 242;
    --primitive-primary-warning-100: 247 230 202;
    --primitive-primary-warning-200: 239 206 149;
    --primitive-primary-warning-300: 232 186 109;
    --primitive-primary-warning-400: 225 170 76;
    --primitive-primary-warning-500: 221 151 32;
    --primitive-primary-warning-600: 183 124 26;
    --primitive-primary-warning-700: 143 97 20;
    --primitive-primary-warning-800: 112 76 16;
    --primitive-primary-warning-900: 90 62 12;
    --primitive-primary-warning-950: 63 44 9;
    --primitive-alpha-white-0: rgb(255 255 255 / 0);
    --primitive-alpha-white-5: rgb(255 255 255 / 0.05);
    --primitive-alpha-white-10: rgb(255 255 255 / 0.1);
    --primitive-alpha-white-20: rgb(255 255 255 / 0.2);
    --primitive-alpha-white-30: rgb(255 255 255 / 0.3);
    --primitive-alpha-white-40: rgb(255 255 255 / 0.4);
    --primitive-alpha-white-50: rgb(255 255 255 / 0.5);
    --primitive-alpha-white-60: rgb(255 255 255 / 0.6);
    --primitive-alpha-white-70: rgb(255 255 255 / 0.7);
    --primitive-alpha-white-80: rgb(255 255 255 / 0.8);
    --primitive-alpha-white-90: rgb(255 255 255 / 0.9);
    --primitive-alpha-white-95: rgb(255 255 255 / 0.95);
    --primitive-alpha-white-100: 255 255 255;
    --primitive-alpha-grey950-0: rgb(13 14 18 / 0);
    --primitive-alpha-grey950-5: rgb(13 14 18 / 0.05);
    --primitive-alpha-grey950-10: rgb(13 14 18 / 0.1);
    --primitive-alpha-grey950-20: rgb(13 14 18 / 0.2);
    --primitive-alpha-grey950-30: rgb(13 14 18 / 0.3);
    --primitive-alpha-grey950-40: rgb(13 14 18 / 0.4);
    --primitive-alpha-grey950-50: rgb(13 14 18 / 0.5);
    --primitive-alpha-grey950-60: rgb(13 14 18 / 0.6);
    --primitive-alpha-grey950-70: rgb(13 14 18 / 0.7);
    --primitive-alpha-grey950-80: rgb(13 14 18 / 0.8);
    --primitive-alpha-grey950-90: rgb(13 14 18 / 0.9);
    --primitive-alpha-grey950-95: rgb(13 14 18 / 0.95);
    --primitive-alpha-grey950-100: rgb(13 14 18);
    --primitive-alpha-black-0: rgb(0 0 0 / 0);
    --primitive-alpha-black-5: rgb(0 0 0 / 0.05);
    --primitive-alpha-black-10: rgb(0 0 0 / 0.1);
    --primitive-alpha-black-20: rgb(0 0 0 / 0.2);
    --primitive-alpha-black-30: rgb(0 0 0 / 0.3);
    --primitive-alpha-black-40: rgb(0 0 0 / 0.4);
    --primitive-alpha-black-50: rgb(0 0 0 / 0.5);
    --primitive-alpha-black-60: rgb(0 0 0 / 0.6);
    --primitive-alpha-black-70: rgb(0 0 0 / 0.7);
    --primitive-alpha-black-80: rgb(0 0 0 / 0.8);
    --primitive-alpha-black-90: rgb(0 0 0 / 0.9);
    --primitive-alpha-black-95: rgb(0 0 0 / 0.95);
    --primitive-alpha-black-100: 0 0 0;
    --primitive-alpha-clearblue-0: rgb(18 119 237 / 0);
    --primitive-alpha-clearblue-5: rgb(18 119 237 / 0.05);
    --primitive-alpha-clearblue-10: rgb(18 119 237 / 0.1);
    --primitive-alpha-clearblue-20: rgb(18 119 237 / 0.2);
    --primitive-alpha-clearblue-30: rgb(18 119 237 / 0.3);
    --primitive-alpha-clearblue-40: rgb(18 119 237 / 0.4);
    --primitive-alpha-clearblue-50: rgb(18 119 237 / 0.5);
    --primitive-alpha-clearblue-60: rgb(18 119 237 / 0.6);
    --primitive-alpha-clearblue-70: rgb(18 119 237 / 0.7);
    --primitive-alpha-clearblue-80: rgb(18 119 237 / 0.8);
    --primitive-alpha-clearblue-90: rgb(18 119 237 / 0.9);
    --primitive-alpha-clearblue-95: rgb(18 119 237 / 0.95);
    --primitive-alpha-clearblue-100: 18 119 237;
    --primitive-alpha-orange-0: rgb(221 124 33 / 0);
    --primitive-alpha-orange-5: rgb(221 124 33 / 0.05);
    --primitive-alpha-orange-10: rgb(221 124 33 / 0.1);
    --primitive-alpha-orange-20: rgb(221 124 33 / 0.2);
    --primitive-alpha-orange-30: rgb(221 124 33 / 0.3);
    --primitive-alpha-orange-40: rgb(221 124 33 / 0.4);
    --primitive-alpha-orange-50: rgb(221 124 33 / 0.5);
    --primitive-alpha-orange-60: rgb(221 124 33 / 0.6);
    --primitive-alpha-orange-70: rgb(221 124 33 / 0.7);
    --primitive-alpha-orange-80: rgb(221 124 33 / 0.8);
    --primitive-alpha-orange-90: rgb(221 124 33 / 0.9);
    --primitive-alpha-orange-95: rgb(221 124 33 / 0.95);
    --primitive-alpha-orange-100: 221 124 33;
    --primitive-alpha-rosered-0: rgb(217 59 106 / 0);
    --primitive-alpha-rosered-5: rgb(217 59 106 / 0.05);
    --primitive-alpha-rosered-10: rgb(217 59 106 / 0.1);
    --primitive-alpha-rosered-20: rgb(217 59 106 / 0.2);
    --primitive-alpha-rosered-30: rgb(217 59 106 / 0.3);
    --primitive-alpha-rosered-40: rgb(217 59 106 / 0.4);
    --primitive-alpha-rosered-50: rgb(217 59 106 / 0.5);
    --primitive-alpha-rosered-60: rgb(217 59 106 / 0.6);
    --primitive-alpha-rosered-70: rgb(217 59 106 / 0.7);
    --primitive-alpha-rosered-80: rgb(217 59 106 / 0.8);
    --primitive-alpha-rosered-90: rgb(217 59 106 / 0.9);
    --primitive-alpha-rosered-95: rgb(217 59 106 / 0.95);
    --primitive-alpha-rosered-100: 217 59 106;
    --primitive-alpha-forest-0: rgb(77 178 148 / 0);
    --primitive-alpha-forest-5: rgb(77 178 148 / 0.05);
    --primitive-alpha-forest-10: rgb(77 178 148 / 0.1);
    --primitive-alpha-forest-20: rgb(77 178 148 / 0.2);
    --primitive-alpha-forest-30: rgb(77 178 148 / 0.3);
    --primitive-alpha-forest-40: rgb(77 178 148 / 0.4);
    --primitive-alpha-forest-50: rgb(77 178 148 / 0.5);
    --primitive-alpha-forest-60: rgb(77 178 148 / 0.6);
    --primitive-alpha-forest-70: rgb(77 178 148 / 0.7);
    --primitive-alpha-forest-80: rgb(77 178 148 / 0.8);
    --primitive-alpha-forest-90: rgb(77 178 148 / 0.9);
    --primitive-alpha-forest-95: rgb(77 178 148 / 0.95);
    --primitive-alpha-forest-100: 77 178 148;
    --primitive-alpha-yellow-0: rgb(223 171 52 / 0);
    --primitive-alpha-yellow-5: rgb(223 171 52 / 0.05);
    --primitive-alpha-yellow-10: rgb(223 171 52 / 0.1);
    --primitive-alpha-yellow-20: rgb(223 171 52 / 0.2);
    --primitive-alpha-yellow-30: rgb(223 171 52 / 0.3);
    --primitive-alpha-yellow-40: rgb(223 171 52 / 0.4);
    --primitive-alpha-yellow-50: rgb(223 171 52 / 0.5);
    --primitive-alpha-yellow-60: rgb(223 171 52 / 0.6);
    --primitive-alpha-yellow-70: rgb(223 171 52 / 0.7);
    --primitive-alpha-yellow-80: rgb(223 171 52 / 0.8);
    --primitive-alpha-yellow-90: rgb(223 171 52 / 0.9);
    --primitive-alpha-yellow-95: rgb(223 171 52 / 0.95);
    --primitive-alpha-yellow-100: 223 171 52;
    --primitive-alpha-leaf-0: rgb(115 176 39 / 0);
    --primitive-alpha-leaf-5: rgb(115 176 39 / 0.05);
    --primitive-alpha-leaf-10: rgb(115 176 39 / 0.1);
    --primitive-alpha-leaf-20: rgb(115 176 39 / 0.2);
    --primitive-alpha-leaf-30: rgb(115 176 39 / 0.3);
    --primitive-alpha-leaf-40: rgb(115 176 39 / 0.4);
    --primitive-alpha-leaf-50: rgb(115 176 39 / 0.5);
    --primitive-alpha-leaf-60: rgb(115 176 39 / 0.6);
    --primitive-alpha-leaf-70: rgb(115 176 39 / 0.7);
    --primitive-alpha-leaf-80: rgb(115 176 39 / 0.8);
    --primitive-alpha-leaf-90: rgb(115 176 39 / 0.9);
    --primitive-alpha-leaf-95: rgb(115 176 39 / 0.95);
    --primitive-alpha-leaf-100: 115 176 39;
    --primitive-alpha-waterblue-0: rgb(82 167 194 / 0);
    --primitive-alpha-waterblue-5: rgb(82 167 194 / 0.05);
    --primitive-alpha-waterblue-10: rgb(82 167 194 / 0.1);
    --primitive-alpha-waterblue-20: rgb(82 167 194 / 0.2);
    --primitive-alpha-waterblue-30: rgb(82 167 194 / 0.3);
    --primitive-alpha-waterblue-40: rgb(82 167 194 / 0.4);
    --primitive-alpha-waterblue-50: rgb(82 167 194 / 0.5);
    --primitive-alpha-waterblue-60: rgb(82 167 194 / 0.6);
    --primitive-alpha-waterblue-70: rgb(82 167 194 / 0.7);
    --primitive-alpha-waterblue-80: rgb(82 167 194 / 0.8);
    --primitive-alpha-waterblue-90: rgb(82 167 194 / 0.9);
    --primitive-alpha-waterblue-95: rgb(82 167 194 / 0.95);
    --primitive-alpha-waterblue-100: 82 167 194;
    --primitive-alpha-purple-0: rgb(136 83 202 / 0);
    --primitive-alpha-purple-5: rgb(136 83 202 / 0.05);
    --primitive-alpha-purple-10: rgb(136 83 202 / 0.1);
    --primitive-alpha-purple-20: rgb(136 83 202 / 0.2);
    --primitive-alpha-purple-30: rgb(136 83 202 / 0.3);
    --primitive-alpha-purple-40: rgb(136 83 202 / 0.4);
    --primitive-alpha-purple-50: rgb(136 83 202 / 0.5);
    --primitive-alpha-purple-60: rgb(136 83 202 / 0.6);
    --primitive-alpha-purple-70: rgb(136 83 202 / 0.7);
    --primitive-alpha-purple-80: rgb(136 83 202 / 0.8);
    --primitive-alpha-purple-90: rgb(136 83 202 / 0.9);
    --primitive-alpha-purple-95: rgb(136 83 202 / 0.95);
    --primitive-alpha-purple-100: 136 83 202;
    --primitive-alpha-magenta-0: rgb(209 46 168 / 0);
    --primitive-alpha-magenta-5: rgb(209 46 168 / 0.05);
    --primitive-alpha-magenta-10: rgb(209 46 168 / 0.1);
    --primitive-alpha-magenta-20: rgb(209 46 168 / 0.2);
    --primitive-alpha-magenta-30: rgb(209 46 168 / 0.3);
    --primitive-alpha-magenta-40: rgb(209 46 168 / 0.4);
    --primitive-alpha-magenta-50: rgb(209 46 168 / 0.5);
    --primitive-alpha-magenta-60: rgb(209 46 168 / 0.6);
    --primitive-alpha-magenta-70: rgb(209 46 168 / 0.7);
    --primitive-alpha-magenta-80: rgb(209 46 168 / 0.8);
    --primitive-alpha-magenta-90: rgb(209 46 168 / 0.9);
    --primitive-alpha-magenta-95: rgb(209 46 168 / 0.95);
    --primitive-alpha-magenta-100: 209 46 168;
    --primitive-alpha-brand-0: rgb(229 69 61 / 0);
    --primitive-alpha-brand-5: rgb(229 69 61 / 0.05);
    --primitive-alpha-brand-10: rgb(229 69 61 / 0.1);
    --primitive-alpha-brand-20: rgb(229 69 61 / 0.2);
    --primitive-alpha-brand-30: rgb(229 69 61 / 0.3);
    --primitive-alpha-brand-40: rgb(229 69 61 / 0.4);
    --primitive-alpha-brand-50: rgb(229 69 61 / 0.5);
    --primitive-alpha-brand-60: rgb(229 69 61 / 0.6);
    --primitive-alpha-brand-70: rgb(229 69 61 / 0.7);
    --primitive-alpha-brand-80: rgb(229 69 61 / 0.8);
    --primitive-alpha-brand-90: rgb(229 69 61 / 0.9);
    --primitive-alpha-brand-95: rgb(229 69 61 / 0.95);
    --primitive-alpha-brand-100: 229 69 61;
    --primitive-alpha-success-0: rgb(59 170 122 / 0);
    --primitive-alpha-success-5: rgb(59 170 122 / 0.05);
    --primitive-alpha-success-10: rgb(59 170 122 / 0.1);
    --primitive-alpha-success-20: rgb(59 170 122 / 0.2);
    --primitive-alpha-success-30: rgb(59 170 122 / 0.3);
    --primitive-alpha-success-40: rgb(59 170 122 / 0.4);
    --primitive-alpha-success-50: rgb(59 170 122 / 0.5);
    --primitive-alpha-success-60: rgb(59 170 122 / 0.6);
    --primitive-alpha-success-70: rgb(59 170 122 / 0.7);
    --primitive-alpha-success-80: rgb(59 170 122 / 0.8);
    --primitive-alpha-success-90: rgb(59 170 122 / 0.9);
    --primitive-alpha-success-95: rgb(59 170 122 / 0.95);
    --primitive-alpha-success-100: 59 170 122;
    --primitive-alpha-warning-0: rgb(221 151 32 / 0);
    --primitive-alpha-warning-5: rgb(221 151 32 / 0.05);
    --primitive-alpha-warning-10: rgb(221 151 32 / 0.1);
    --primitive-alpha-warning-20: rgb(221 151 32 / 0.2);
    --primitive-alpha-warning-30: rgb(221 151 32 / 0.3);
    --primitive-alpha-warning-40: rgb(221 151 32 / 0.4);
    --primitive-alpha-warning-50: rgb(221 151 32 / 0.5);
    --primitive-alpha-warning-60: rgb(221 151 32 / 0.6);
    --primitive-alpha-warning-70: rgb(221 151 32 / 0.7);
    --primitive-alpha-warning-80: rgb(221 151 32 / 0.8);
    --primitive-alpha-warning-90: rgb(221 151 32 / 0.9);
    --primitive-alpha-warning-95: rgb(221 151 32 / 0.95);
    --primitive-alpha-warning-100: 221 151 32;
    --primitive-alpha-error-0: rgb(229 69 61 / 0);
    --primitive-alpha-error-5: rgb(229 69 61 / 0.05);
    --primitive-alpha-error-10: rgb(229 69 61 / 0.1);
    --primitive-alpha-error-20: rgb(229 69 61 / 0.2);
    --primitive-alpha-error-30: rgb(229 69 61 / 0.3);
    --primitive-alpha-error-40: rgb(229 69 61 / 0.4);
    --primitive-alpha-error-50: rgb(229 69 61 / 0.5);
    --primitive-alpha-error-60: rgb(229 69 61 / 0.6);
    --primitive-alpha-error-70: rgb(229 69 61 / 0.7);
    --primitive-alpha-error-80: rgb(229 69 61 / 0.8);
    --primitive-alpha-error-90: rgb(229 69 61 / 0.9);
    --primitive-alpha-error-95: rgb(229 69 61 / 0.95);
    --primitive-alpha-error-100: 229 69 61;
    --primitive-secondary-leaf-25: 245 249 240;
    --primitive-secondary-leaf-50: 223 237 207;
    --primitive-secondary-leaf-100: 202 225 173;
    --primitive-secondary-leaf-200: 180 213 140;
    --primitive-secondary-leaf-300: 158 200 106;
    --primitive-secondary-leaf-400: 137 188 72;
    --primitive-secondary-leaf-500: 115 176 39;
    --primitive-secondary-leaf-600: 90 138 30;
    --primitive-secondary-leaf-700: 73 113 25;
    --primitive-secondary-leaf-800: 60 92 20;
    --primitive-secondary-leaf-900: 47 72 15;
    --primitive-secondary-leaf-950: 28 44 9;
    --primitive-secondary-forest-25: 241 249 246;
    --primitive-secondary-forest-50: 220 239 234;
    --primitive-secondary-forest-100: 195 229 218;
    --primitive-secondary-forest-200: 156 212 196;
    --primitive-secondary-forest-300: 130 201 180;
    --primitive-secondary-forest-400: 106 190 165;
    --primitive-secondary-forest-500: 77 178 148;
    --primitive-secondary-forest-600: 60 139 116;
    --primitive-secondary-forest-700: 46 107 89;
    --primitive-secondary-forest-800: 37 86 71;
    --primitive-secondary-forest-900: 26 61 51;
    --primitive-secondary-forest-950: 19 44 37;
    --primitive-secondary-waterblue-25: 240 247 250;
    --primitive-secondary-waterblue-50: 218 236 241;
    --primitive-secondary-waterblue-100: 195 225 234;
    --primitive-secondary-waterblue-200: 176 215 227;
    --primitive-secondary-waterblue-300: 146 200 216;
    --primitive-secondary-waterblue-400: 116 184 206;
    --primitive-secondary-waterblue-500: 82 167 194;
    --primitive-secondary-waterblue-600: 66 136 158;
    --primitive-secondary-waterblue-700: 54 111 129;
    --primitive-secondary-waterblue-800: 42 86 100;
    --primitive-secondary-waterblue-900: 31 65 76;
    --primitive-secondary-waterblue-950: 20 41 48;
    --primitive-secondary-clearblue-25: 236 244 254;
    --primitive-secondary-clearblue-50: 208 228 251;
    --primitive-secondary-clearblue-100: 160 201 248;
    --primitive-secondary-clearblue-200: 118 177 244;
    --primitive-secondary-clearblue-300: 80 155 241;
    --primitive-secondary-clearblue-400: 47 136 239;
    --primitive-secondary-clearblue-500: 18 119 237;
    --primitive-secondary-clearblue-600: 14 96 190;
    --primitive-secondary-clearblue-700: 11 76 152;
    --primitive-secondary-clearblue-800: 9 59 118;
    --primitive-secondary-clearblue-900: 6 44 88;
    --primitive-secondary-clearblue-950: 5 33 67;
    --primitive-secondary-purple-25: 244 239 250;
    --primitive-secondary-purple-50: 234 224 245;
    --primitive-secondary-purple-100: 209 189 234;
    --primitive-secondary-purple-200: 193 166 227;
    --primitive-secondary-purple-300: 176 142 220;
    --primitive-secondary-purple-400: 155 111 210;
    --primitive-secondary-purple-500: 136 83 202;
    --primitive-secondary-purple-600: 114 69 168;
    --primitive-secondary-purple-700: 93 56 138;
    --primitive-secondary-purple-800: 76 46 112;
    --primitive-secondary-purple-900: 59 36 87;
    --primitive-secondary-purple-950: 45 26 66;
    --primitive-secondary-magenta-25: 251 238 248;
    --primitive-secondary-magenta-50: 247 218 239;
    --primitive-secondary-magenta-100: 240 188 227;
    --primitive-secondary-magenta-200: 232 151 211;
    --primitive-secondary-magenta-300: 225 122 199;
    --primitive-secondary-magenta-400: 218 88 185;
    --primitive-secondary-magenta-500: 209 46 168;
    --primitive-secondary-magenta-600: 172 37 139;
    --primitive-secondary-magenta-700: 138 30 112;
    --primitive-secondary-magenta-800: 109 24 88;
    --primitive-secondary-magenta-900: 84 18 68;
    --primitive-secondary-magenta-950: 63 13 51;
    --primitive-secondary-rosered-25: 252 238 242;
    --primitive-secondary-rosered-50: 246 212 222;
    --primitive-secondary-rosered-100: 245 186 203;
    --primitive-secondary-rosered-200: 237 135 166;
    --primitive-secondary-rosered-300: 229 108 144;
    --primitive-secondary-rosered-400: 224 88 128;
    --primitive-secondary-rosered-500: 217 59 106;
    --primitive-secondary-rosered-600: 189 51 93;
    --primitive-secondary-rosered-700: 153 41 74;
    --primitive-secondary-rosered-800: 122 33 60;
    --primitive-secondary-rosered-900: 87 23 42;
    --primitive-secondary-rosered-950: 74 20 36;
    --primitive-secondary-orange-25: 252 244 237;
    --primitive-secondary-orange-50: 247 224 203;
    --primitive-secondary-orange-100: 242 204 169;
    --primitive-secondary-orange-200: 237 184 135;
    --primitive-secondary-orange-300: 231 164 101;
    --primitive-secondary-orange-400: 226 144 67;
    --primitive-secondary-orange-500: 221 124 33;
    --primitive-secondary-orange-600: 194 106 28;
    --primitive-secondary-orange-700: 166 89 23;
    --primitive-secondary-orange-800: 139 71 18;
    --primitive-secondary-orange-900: 111 54 13;
    --primitive-secondary-orange-950: 84 36 8;
    --primitive-secondary-yellow-25: 254 253 241;
    --primitive-secondary-yellow-50: 253 251 233;
    --primitive-secondary-yellow-100: 253 246 199;
    --primitive-secondary-yellow-200: 251 237 158;
    --primitive-secondary-yellow-300: 248 225 127;
    --primitive-secondary-yellow-400: 241 197 63;
    --primitive-secondary-yellow-500: 223 171 52;
    --primitive-secondary-yellow-600: 191 135 39;
    --primitive-secondary-yellow-700: 151 95 27;
    --primitive-secondary-yellow-800: 124 75 25;
    --primitive-secondary-yellow-900: 105 61 25;
    --primitive-secondary-yellow-950: 78 46 18;
    --utility-alpha-white-0: var(--primitive-alpha-white-0);
    --utility-alpha-white-5: var(--primitive-alpha-white-5);
    --utility-alpha-white-10: var(--primitive-alpha-white-10);
    --utility-alpha-white-20: var(--primitive-alpha-white-20);
    --utility-alpha-white-30: var(--primitive-alpha-white-30);
    --utility-alpha-white-40: var(--primitive-alpha-white-40);
    --utility-alpha-white-50: var(--primitive-alpha-white-50);
    --utility-alpha-white-60: var(--primitive-alpha-white-60);
    --utility-alpha-white-70: var(--primitive-alpha-white-70);
    --utility-alpha-white-80: var(--primitive-alpha-white-80);
    --utility-alpha-white-90: var(--primitive-alpha-white-90);
    --utility-alpha-white-100: var(--primitive-alpha-white-100);
    --utility-alpha-black-0: var(--primitive-alpha-black-0);
    --utility-alpha-black-5: var(--primitive-alpha-black-5);
    --utility-alpha-black-10: var(--primitive-alpha-black-10);
    --utility-alpha-black-20: var(--primitive-alpha-black-20);
    --utility-alpha-black-30: var(--primitive-alpha-black-30);
    --utility-alpha-black-40: var(--primitive-alpha-black-40);
    --utility-alpha-black-50: var(--primitive-alpha-black-50);
    --utility-alpha-black-60: var(--primitive-alpha-black-60);
    --utility-alpha-black-70: var(--primitive-alpha-black-70);
    --utility-alpha-black-80: var(--primitive-alpha-black-80);
    --utility-alpha-black-90: var(--primitive-alpha-black-90);
    --utility-alpha-black-100: var(--primitive-alpha-black-100);
    --utility-color-brand-25: var(--primitive-primary-brand-25);
    --utility-color-brand-50: var(--primitive-primary-brand-50);
    --utility-color-brand-100: var(--primitive-primary-brand-100);
    --utility-color-brand-200: var(--primitive-primary-brand-200);
    --utility-color-brand-300: var(--primitive-primary-brand-300);
    --utility-color-brand-400: var(--primitive-primary-brand-400);
    --utility-color-brand-500: var(--primitive-primary-brand-500);
    --utility-color-brand-600: var(--primitive-primary-brand-600);
    --utility-color-brand-700: var(--primitive-primary-brand-700);
    --utility-color-brand-800: var(--primitive-primary-brand-800);
    --utility-color-brand-900: var(--primitive-primary-brand-900);
    --utility-color-brand-950: var(--primitive-primary-brand-950);
    --utility-color-brand-alt-25: var(--primitive-primary-brand-25);
    --utility-color-brand-alt-50: var(--primitive-primary-brand-50);
    --utility-color-brand-alt-100: var(--primitive-primary-brand-100);
    --utility-color-brand-alt-200: var(--primitive-primary-brand-200);
    --utility-color-brand-alt-300: var(--primitive-primary-brand-300);
    --utility-color-brand-alt-400: var(--primitive-primary-brand-400);
    --utility-color-brand-alt-500: var(--primitive-primary-brand-500);
    --utility-color-brand-alt-600: var(--primitive-primary-brand-600);
    --utility-color-brand-alt-700: var(--primitive-primary-brand-700);
    --utility-color-brand-alt-800: var(--primitive-primary-brand-800);
    --utility-color-brand-alt-900: var(--primitive-primary-brand-950);
    --utility-color-brand-alt-950: var(--primitive-primary-brand-900);
    --utility-color-success-25: var(--primitive-primary-success-25);
    --utility-color-success-50: var(--primitive-primary-success-50);
    --utility-color-success-100: var(--primitive-primary-success-100);
    --utility-color-success-200: var(--primitive-primary-success-200);
    --utility-color-success-300: var(--primitive-primary-success-300);
    --utility-color-success-400: var(--primitive-primary-success-400);
    --utility-color-success-500: var(--primitive-primary-success-500);
    --utility-color-success-600: var(--primitive-primary-success-600);
    --utility-color-success-700: var(--primitive-primary-success-700);
    --utility-color-success-800: var(--primitive-primary-success-800);
    --utility-color-success-900: var(--primitive-primary-success-900);
    --utility-color-success-950: var(--primitive-primary-success-950);
    --utility-color-warning-25: var(--primitive-primary-warning-25);
    --utility-color-warning-50: var(--primitive-primary-warning-50);
    --utility-color-warning-100: var(--primitive-primary-warning-100);
    --utility-color-warning-200: var(--primitive-primary-warning-200);
    --utility-color-warning-300: var(--primitive-primary-warning-300);
    --utility-color-warning-400: var(--primitive-primary-warning-400);
    --utility-color-warning-500: var(--primitive-primary-warning-500);
    --utility-color-warning-600: var(--primitive-primary-warning-600);
    --utility-color-warning-700: var(--primitive-primary-warning-700);
    --utility-color-warning-800: var(--primitive-primary-warning-800);
    --utility-color-warning-900: var(--primitive-primary-warning-900);
    --utility-color-warning-950: var(--primitive-primary-warning-950);
    --utility-color-error-25: var(--primitive-primary-error-25);
    --utility-color-error-50: var(--primitive-primary-error-50);
    --utility-color-error-100: var(--primitive-primary-error-100);
    --utility-color-error-200: var(--primitive-primary-error-200);
    --utility-color-error-300: var(--primitive-primary-error-300);
    --utility-color-error-400: var(--primitive-primary-error-400);
    --utility-color-error-500: var(--primitive-primary-error-500);
    --utility-color-error-600: var(--primitive-primary-error-600);
    --utility-color-error-700: var(--primitive-primary-error-700);
    --utility-color-error-800: var(--primitive-primary-error-800);
    --utility-color-error-900: var(--primitive-primary-error-900);
    --utility-color-error-950: var(--primitive-primary-error-950);
    --utility-color-leaf-25: var(--primitive-secondary-leaf-25);
    --utility-color-leaf-50: var(--primitive-secondary-leaf-50);
    --utility-color-leaf-100: var(--primitive-secondary-leaf-100);
    --utility-color-leaf-200: var(--primitive-secondary-leaf-200);
    --utility-color-leaf-300: var(--primitive-secondary-leaf-300);
    --utility-color-leaf-400: var(--primitive-secondary-leaf-400);
    --utility-color-leaf-500: var(--primitive-secondary-leaf-500);
    --utility-color-leaf-600: var(--primitive-secondary-leaf-600);
    --utility-color-leaf-700: var(--primitive-secondary-leaf-700);
    --utility-color-leaf-800: var(--primitive-secondary-leaf-800);
    --utility-color-leaf-900: var(--primitive-secondary-leaf-900);
    --utility-color-leaf-950: var(--primitive-secondary-leaf-950);
    --utility-color-forest-25: var(--primitive-secondary-forest-25);
    --utility-color-forest-50: var(--primitive-secondary-forest-50);
    --utility-color-forest-100: var(--primitive-secondary-forest-100);
    --utility-color-forest-200: var(--primitive-secondary-forest-200);
    --utility-color-forest-300: var(--primitive-secondary-forest-300);
    --utility-color-forest-400: var(--primitive-secondary-forest-400);
    --utility-color-forest-500: var(--primitive-secondary-forest-500);
    --utility-color-forest-600: var(--primitive-secondary-forest-600);
    --utility-color-forest-700: var(--primitive-secondary-forest-700);
    --utility-color-forest-800: var(--primitive-secondary-forest-800);
    --utility-color-forest-900: var(--primitive-secondary-forest-900);
    --utility-color-forest-950: var(--primitive-secondary-forest-950);
    --utility-color-waterblue-25: var(--primitive-secondary-waterblue-25);
    --utility-color-waterblue-50: var(--primitive-secondary-waterblue-50);
    --utility-color-waterblue-100: var(--primitive-secondary-waterblue-100);
    --utility-color-waterblue-200: var(--primitive-secondary-waterblue-200);
    --utility-color-waterblue-300: var(--primitive-secondary-waterblue-300);
    --utility-color-waterblue-400: var(--primitive-secondary-waterblue-400);
    --utility-color-waterblue-500: var(--primitive-secondary-waterblue-500);
    --utility-color-waterblue-600: var(--primitive-secondary-waterblue-600);
    --utility-color-waterblue-700: var(--primitive-secondary-waterblue-700);
    --utility-color-waterblue-800: var(--primitive-secondary-waterblue-800);
    --utility-color-waterblue-900: var(--primitive-secondary-waterblue-900);
    --utility-color-waterblue-950: var(--primitive-secondary-waterblue-950);
    --utility-color-clearblue-25: var(--primitive-secondary-clearblue-25);
    --utility-color-clearblue-50: var(--primitive-secondary-clearblue-50);
    --utility-color-clearblue-100: var(--primitive-secondary-clearblue-100);
    --utility-color-clearblue-200: var(--primitive-secondary-clearblue-200);
    --utility-color-clearblue-300: var(--primitive-secondary-clearblue-300);
    --utility-color-clearblue-400: var(--primitive-secondary-clearblue-400);
    --utility-color-clearblue-500: var(--primitive-secondary-clearblue-500);
    --utility-color-clearblue-600: var(--primitive-secondary-clearblue-600);
    --utility-color-clearblue-700: var(--primitive-secondary-clearblue-700);
    --utility-color-clearblue-800: var(--primitive-secondary-clearblue-800);
    --utility-color-clearblue-900: var(--primitive-secondary-clearblue-900);
    --utility-color-clearblue-950: var(--primitive-secondary-clearblue-900);
    --utility-color-purple-25: var(--primitive-secondary-purple-25);
    --utility-color-purple-50: var(--primitive-secondary-purple-50);
    --utility-color-purple-100: var(--primitive-secondary-purple-100);
    --utility-color-purple-200: var(--primitive-secondary-purple-200);
    --utility-color-purple-300: var(--primitive-secondary-purple-300);
    --utility-color-purple-400: var(--primitive-secondary-purple-400);
    --utility-color-purple-500: var(--primitive-secondary-purple-500);
    --utility-color-purple-600: var(--primitive-secondary-purple-600);
    --utility-color-purple-700: var(--primitive-secondary-purple-700);
    --utility-color-purple-800: var(--primitive-secondary-purple-800);
    --utility-color-purple-900: var(--primitive-secondary-purple-900);
    --utility-color-purple-950: var(--primitive-secondary-purple-950);
    --utility-color-magenta-25: var(--primitive-secondary-magenta-25);
    --utility-color-magenta-50: var(--primitive-secondary-magenta-50);
    --utility-color-magenta-100: var(--primitive-secondary-magenta-100);
    --utility-color-magenta-200: var(--primitive-secondary-magenta-200);
    --utility-color-magenta-300: var(--primitive-secondary-magenta-300);
    --utility-color-magenta-400: var(--primitive-secondary-magenta-400);
    --utility-color-magenta-500: var(--primitive-secondary-magenta-500);
    --utility-color-magenta-600: var(--primitive-secondary-magenta-600);
    --utility-color-magenta-700: var(--primitive-secondary-magenta-700);
    --utility-color-magenta-800: var(--primitive-secondary-magenta-800);
    --utility-color-magenta-900: var(--primitive-secondary-magenta-900);
    --utility-color-magenta-950: var(--primitive-secondary-magenta-950);
    --utility-color-rosered-25: var(--primitive-secondary-rosered-25);
    --utility-color-rosered-50: var(--primitive-secondary-rosered-50);
    --utility-color-rosered-100: var(--primitive-secondary-rosered-100);
    --utility-color-rosered-200: var(--primitive-secondary-rosered-200);
    --utility-color-rosered-300: var(--primitive-secondary-rosered-300);
    --utility-color-rosered-400: var(--primitive-secondary-rosered-400);
    --utility-color-rosered-500: var(--primitive-secondary-rosered-500);
    --utility-color-rosered-600: var(--primitive-secondary-rosered-600);
    --utility-color-rosered-700: var(--primitive-secondary-rosered-700);
    --utility-color-rosered-800: var(--primitive-secondary-rosered-800);
    --utility-color-rosered-900: var(--primitive-secondary-rosered-900);
    --utility-color-rosered-950: var(--primitive-secondary-rosered-950);
    --utility-color-orange-25: var(--primitive-secondary-orange-25);
    --utility-color-orange-50: var(--primitive-secondary-orange-50);
    --utility-color-orange-100: var(--primitive-secondary-orange-100);
    --utility-color-orange-200: var(--primitive-secondary-orange-200);
    --utility-color-orange-300: var(--primitive-secondary-orange-300);
    --utility-color-orange-400: var(--primitive-secondary-orange-400);
    --utility-color-orange-500: var(--primitive-secondary-orange-500);
    --utility-color-orange-600: var(--primitive-secondary-orange-600);
    --utility-color-orange-700: var(--primitive-secondary-orange-700);
    --utility-color-orange-800: var(--primitive-secondary-orange-800);
    --utility-color-orange-900: var(--primitive-secondary-orange-900);
    --utility-color-orange-950: var(--primitive-secondary-orange-950);
    --utility-color-yellow-25: var(--primitive-secondary-yellow-25);
    --utility-color-yellow-50: var(--primitive-secondary-yellow-50);
    --utility-color-yellow-100: var(--primitive-secondary-yellow-100);
    --utility-color-yellow-200: var(--primitive-secondary-yellow-200);
    --utility-color-yellow-300: var(--primitive-secondary-yellow-300);
    --utility-color-yellow-400: var(--primitive-secondary-yellow-400);
    --utility-color-yellow-500: var(--primitive-secondary-yellow-500);
    --utility-color-yellow-600: var(--primitive-secondary-yellow-600);
    --utility-color-yellow-700: var(--primitive-secondary-yellow-700);
    --utility-color-yellow-800: var(--primitive-secondary-yellow-800);
    --utility-color-yellow-900: var(--primitive-secondary-yellow-900);
    --utility-color-yellow-950: var(--primitive-secondary-yellow-950);
    --utility-color-grey-25: var(--primitive-primary-grey-25);
    --utility-color-grey-50: var(--primitive-primary-grey-50);
    --utility-color-grey-100: var(--primitive-primary-grey-100);
    --utility-color-grey-200: var(--primitive-primary-grey-200);
    --utility-color-grey-300: var(--primitive-primary-grey-300);
    --utility-color-grey-400: var(--primitive-primary-grey-400);
    --utility-color-grey-500: var(--primitive-primary-grey-500);
    --utility-color-grey-600: var(--primitive-primary-grey-600);
    --utility-color-grey-700: var(--primitive-primary-grey-700);
    --utility-color-grey-800: var(--primitive-primary-grey-800);
    --utility-color-grey-900: var(--primitive-primary-grey-900);
    --utility-color-grey-950: var(--primitive-primary-grey-950);
    --utility-color-static-brand-25: var(--primitive-primary-brand-25);
    --utility-color-static-brand-50: var(--primitive-primary-brand-50);
    --utility-color-static-brand-100: var(--primitive-primary-brand-100);
    --utility-color-static-brand-200: var(--primitive-primary-brand-200);
    --utility-color-static-brand-300: var(--primitive-primary-brand-300);
    --utility-color-static-brand-400: var(--primitive-primary-brand-400);
    --utility-color-static-brand-500: var(--primitive-primary-brand-500);
    --utility-color-static-brand-600: var(--primitive-primary-brand-600);
    --utility-color-static-brand-700: var(--primitive-primary-brand-700);
    --utility-color-static-brand-800: var(--primitive-primary-brand-800);
    --utility-color-static-brand-900: var(--primitive-primary-brand-900);
    --utility-color-static-brand-950: var(--primitive-primary-brand-950);
    --utility-color-static-error-25: var(--primitive-primary-error-25);
    --utility-color-static-error-50: var(--primitive-primary-error-50);
    --utility-color-static-error-100: var(--primitive-primary-error-100);
    --utility-color-static-error-200: var(--primitive-primary-error-200);
    --utility-color-static-error-300: var(--primitive-primary-error-300);
    --utility-color-static-error-400: var(--primitive-primary-error-400);
    --utility-color-static-error-500: var(--primitive-primary-error-500);
    --utility-color-static-error-600: var(--primitive-primary-error-600);
    --utility-color-static-error-700: var(--primitive-primary-error-700);
    --utility-color-static-error-800: var(--primitive-primary-error-800);
    --utility-color-static-error-900: var(--primitive-primary-error-900);
    --utility-color-static-error-950: var(--primitive-primary-error-950);
    --utility-color-static-warning-25: var(--primitive-primary-warning-25);
    --utility-color-static-warning-50: var(--primitive-primary-warning-50);
    --utility-color-static-warning-100: var(--primitive-primary-warning-100);
    --utility-color-static-warning-200: var(--primitive-primary-warning-200);
    --utility-color-static-warning-300: var(--primitive-primary-warning-300);
    --utility-color-static-warning-400: var(--primitive-primary-warning-400);
    --utility-color-static-warning-500: var(--primitive-primary-warning-500);
    --utility-color-static-warning-600: var(--primitive-primary-warning-600);
    --utility-color-static-warning-700: var(--primitive-primary-warning-700);
    --utility-color-static-warning-800: var(--primitive-primary-warning-800);
    --utility-color-static-warning-900: var(--primitive-primary-warning-900);
    --utility-color-static-warning-950: var(--primitive-primary-warning-950);
    --utility-color-static-success-25: var(--primitive-primary-success-25);
    --utility-color-static-success-50: var(--primitive-primary-success-50);
    --utility-color-static-success-100: var(--primitive-primary-success-100);
    --utility-color-static-success-200: var(--primitive-primary-success-200);
    --utility-color-static-success-300: var(--primitive-primary-success-300);
    --utility-color-static-success-400: var(--primitive-primary-success-400);
    --utility-color-static-success-500: var(--primitive-primary-success-500);
    --utility-color-static-success-600: var(--primitive-primary-success-600);
    --utility-color-static-success-700: var(--primitive-primary-success-700);
    --utility-color-static-success-800: var(--primitive-primary-success-800);
    --utility-color-static-success-900: var(--primitive-primary-success-900);
    --utility-color-static-success-950: var(--primitive-primary-success-950);
    --utility-color-static-leaf-25: var(--primitive-secondary-leaf-25);
    --utility-color-static-leaf-50: var(--primitive-secondary-leaf-50);
    --utility-color-static-leaf-100: var(--primitive-secondary-leaf-100);
    --utility-color-static-leaf-200: var(--primitive-secondary-leaf-200);
    --utility-color-static-leaf-300: var(--primitive-secondary-leaf-300);
    --utility-color-static-leaf-400: var(--primitive-secondary-leaf-400);
    --utility-color-static-leaf-500: var(--primitive-secondary-leaf-500);
    --utility-color-static-leaf-600: var(--primitive-secondary-leaf-600);
    --utility-color-static-leaf-700: var(--primitive-secondary-leaf-700);
    --utility-color-static-leaf-800: var(--primitive-secondary-leaf-800);
    --utility-color-static-leaf-900: var(--primitive-secondary-leaf-900);
    --utility-color-static-leaf-950: var(--primitive-secondary-leaf-950);
    --utility-color-static-forest-25: var(--primitive-secondary-forest-25);
    --utility-color-static-forest-50: var(--primitive-secondary-forest-50);
    --utility-color-static-forest-100: var(--primitive-secondary-forest-100);
    --utility-color-static-forest-200: var(--primitive-secondary-forest-200);
    --utility-color-static-forest-300: var(--primitive-secondary-forest-300);
    --utility-color-static-forest-400: var(--primitive-secondary-forest-400);
    --utility-color-static-forest-500: var(--primitive-secondary-forest-500);
    --utility-color-static-forest-600: var(--primitive-secondary-forest-600);
    --utility-color-static-forest-700: var(--primitive-secondary-forest-700);
    --utility-color-static-forest-800: var(--primitive-secondary-forest-800);
    --utility-color-static-forest-900: var(--primitive-secondary-forest-900);
    --utility-color-static-forest-950: var(--primitive-secondary-forest-950);
    --utility-color-static-waterblue-25: var(--primitive-secondary-waterblue-25);
    --utility-color-static-waterblue-50: var(--primitive-secondary-waterblue-50);
    --utility-color-static-waterblue-100: var(--primitive-secondary-waterblue-100);
    --utility-color-static-waterblue-200: var(--primitive-secondary-waterblue-200);
    --utility-color-static-waterblue-300: var(--primitive-secondary-waterblue-300);
    --utility-color-static-waterblue-400: var(--primitive-secondary-waterblue-400);
    --utility-color-static-waterblue-500: var(--primitive-secondary-waterblue-500);
    --utility-color-static-waterblue-600: var(--primitive-secondary-waterblue-600);
    --utility-color-static-waterblue-700: var(--primitive-secondary-waterblue-700);
    --utility-color-static-waterblue-800: var(--primitive-secondary-waterblue-800);
    --utility-color-static-waterblue-900: var(--primitive-secondary-waterblue-900);
    --utility-color-static-waterblue-950: var(--primitive-secondary-waterblue-900);
    --utility-color-static-clearblue-25: var(--primitive-secondary-clearblue-25);
    --utility-color-static-clearblue-50: var(--primitive-secondary-clearblue-50);
    --utility-color-static-clearblue-100: var(--primitive-secondary-clearblue-100);
    --utility-color-static-clearblue-200: var(--primitive-secondary-clearblue-200);
    --utility-color-static-clearblue-300: var(--primitive-secondary-clearblue-300);
    --utility-color-static-clearblue-400: var(--primitive-secondary-clearblue-400);
    --utility-color-static-clearblue-500: var(--primitive-secondary-clearblue-500);
    --utility-color-static-clearblue-600: var(--primitive-secondary-clearblue-600);
    --utility-color-static-clearblue-700: var(--primitive-secondary-clearblue-700);
    --utility-color-static-clearblue-800: var(--primitive-secondary-clearblue-800);
    --utility-color-static-clearblue-900: var(--primitive-secondary-clearblue-900);
    --utility-color-static-clearblue-950: var(--primitive-secondary-clearblue-950);
    --utility-color-static-purple-25: var(--primitive-secondary-purple-25);
    --utility-color-static-purple-50: var(--primitive-secondary-purple-50);
    --utility-color-static-purple-100: var(--primitive-secondary-purple-100);
    --utility-color-static-purple-200: var(--primitive-secondary-purple-200);
    --utility-color-static-purple-300: var(--primitive-secondary-purple-300);
    --utility-color-static-purple-400: var(--primitive-secondary-purple-400);
    --utility-color-static-purple-500: var(--primitive-secondary-purple-500);
    --utility-color-static-purple-600: var(--primitive-secondary-purple-600);
    --utility-color-static-purple-700: var(--primitive-secondary-purple-700);
    --utility-color-static-purple-800: var(--primitive-secondary-purple-800);
    --utility-color-static-purple-900: var(--primitive-secondary-purple-900);
    --utility-color-static-purple-950: var(--primitive-secondary-purple-950);
    --utility-color-static-magenta-25: var(--primitive-secondary-magenta-25);
    --utility-color-static-magenta-50: var(--primitive-secondary-magenta-50);
    --utility-color-static-magenta-100: var(--primitive-secondary-magenta-100);
    --utility-color-static-magenta-200: var(--primitive-secondary-magenta-200);
    --utility-color-static-magenta-300: var(--primitive-secondary-magenta-300);
    --utility-color-static-magenta-400: var(--primitive-secondary-magenta-400);
    --utility-color-static-magenta-500: var(--primitive-secondary-magenta-500);
    --utility-color-static-magenta-600: var(--primitive-secondary-magenta-600);
    --utility-color-static-magenta-700: var(--primitive-secondary-magenta-700);
    --utility-color-static-magenta-800: var(--primitive-secondary-magenta-800);
    --utility-color-static-magenta-900: var(--primitive-secondary-magenta-900);
    --utility-color-static-magenta-950: var(--primitive-secondary-magenta-950);
    --utility-color-static-rosered-25: var(--primitive-secondary-rosered-25);
    --utility-color-static-rosered-50: var(--primitive-secondary-rosered-50);
    --utility-color-static-rosered-100: var(--primitive-secondary-rosered-100);
    --utility-color-static-rosered-200: var(--primitive-secondary-rosered-200);
    --utility-color-static-rosered-300: var(--primitive-secondary-rosered-300);
    --utility-color-static-rosered-400: var(--primitive-secondary-rosered-400);
    --utility-color-static-rosered-500: var(--primitive-secondary-rosered-500);
    --utility-color-static-rosered-600: var(--primitive-secondary-rosered-600);
    --utility-color-static-rosered-700: var(--primitive-secondary-rosered-700);
    --utility-color-static-rosered-800: var(--primitive-secondary-rosered-800);
    --utility-color-static-rosered-900: var(--primitive-secondary-rosered-900);
    --utility-color-static-rosered-950: var(--primitive-secondary-rosered-950);
    --utility-color-static-orange-25: var(--primitive-secondary-orange-25);
    --utility-color-static-orange-50: var(--primitive-secondary-orange-50);
    --utility-color-static-orange-100: var(--primitive-secondary-orange-100);
    --utility-color-static-orange-200: var(--primitive-secondary-orange-200);
    --utility-color-static-orange-300: var(--primitive-secondary-orange-300);
    --utility-color-static-orange-400: var(--primitive-secondary-orange-400);
    --utility-color-static-orange-500: var(--primitive-secondary-orange-500);
    --utility-color-static-orange-600: var(--primitive-secondary-orange-600);
    --utility-color-static-orange-700: var(--primitive-secondary-orange-700);
    --utility-color-static-orange-800: var(--primitive-secondary-orange-800);
    --utility-color-static-orange-900: var(--primitive-secondary-orange-900);
    --utility-color-static-orange-950: var(--primitive-secondary-orange-950);
    --utility-color-static-yellow-25: var(--primitive-secondary-yellow-25);
    --utility-color-static-yellow-50: var(--primitive-secondary-yellow-50);
    --utility-color-static-yellow-100: var(--primitive-secondary-yellow-100);
    --utility-color-static-yellow-200: var(--primitive-secondary-yellow-200);
    --utility-color-static-yellow-300: var(--primitive-secondary-yellow-300);
    --utility-color-static-yellow-400: var(--primitive-secondary-yellow-400);
    --utility-color-static-yellow-500: var(--primitive-secondary-yellow-500);
    --utility-color-static-yellow-600: var(--primitive-secondary-yellow-600);
    --utility-color-static-yellow-700: var(--primitive-secondary-yellow-700);
    --utility-color-static-yellow-800: var(--primitive-secondary-yellow-800);
    --utility-color-static-yellow-900: var(--primitive-secondary-yellow-900);
    --utility-color-static-yellow-950: var(--primitive-secondary-yellow-950);
    --utility-color-static-grey-25: var(--primitive-primary-grey-25);
    --utility-color-static-grey-50: var(--primitive-primary-grey-50);
    --utility-color-static-grey-100: var(--primitive-primary-grey-100);
    --utility-color-static-grey-200: var(--primitive-primary-grey-200);
    --utility-color-static-grey-300: var(--primitive-primary-grey-300);
    --utility-color-static-grey-400: var(--primitive-primary-grey-400);
    --utility-color-static-grey-500: var(--primitive-primary-grey-500);
    --utility-color-static-grey-600: var(--primitive-primary-grey-600);
    --utility-color-static-grey-700: var(--primitive-primary-grey-700);
    --utility-color-static-grey-800: var(--primitive-primary-grey-800);
    --utility-color-static-grey-900: var(--primitive-primary-grey-900);
    --utility-color-static-grey-950: var(--primitive-primary-grey-950);
    --alpha-static-white-0: var(--primitive-alpha-white-0);
    --alpha-static-white-5: var(--primitive-alpha-white-5);
    --alpha-static-white-10: var(--primitive-alpha-white-10);
    --alpha-static-white-20: var(--primitive-alpha-white-20);
    --alpha-static-white-30: var(--primitive-alpha-white-30);
    --alpha-static-white-40: var(--primitive-alpha-white-40);
    --alpha-static-white-50: var(--primitive-alpha-white-50);
    --alpha-static-white-60: var(--primitive-alpha-white-60);
    --alpha-static-white-70: var(--primitive-alpha-white-70);
    --alpha-static-white-80: var(--primitive-alpha-white-80);
    --alpha-static-white-90: var(--primitive-alpha-white-90);
    --alpha-static-white-95: var(--primitive-alpha-white-95);
    --alpha-static-white-100: var(--primitive-alpha-white-100);
    --alpha-static-black-0: var(--primitive-alpha-black-0);
    --alpha-static-black-5: var(--primitive-alpha-black-5);
    --alpha-static-black-10: var(--primitive-alpha-black-10);
    --alpha-static-black-20: var(--primitive-alpha-black-20);
    --alpha-static-black-30: var(--primitive-alpha-black-30);
    --alpha-static-black-40: var(--primitive-alpha-black-40);
    --alpha-static-black-50: var(--primitive-alpha-black-50);
    --alpha-static-black-60: var(--primitive-alpha-black-60);
    --alpha-static-black-70: var(--primitive-alpha-black-70);
    --alpha-static-black-80: var(--primitive-alpha-black-80);
    --alpha-static-black-90: var(--primitive-alpha-black-90);
    --alpha-static-black-95: var(--primitive-alpha-black-95);
    --alpha-static-black-100: var(--primitive-alpha-black-100);
    --alpha-static-yellow-0: var(--primitive-alpha-yellow-0);
    --alpha-static-yellow-5: var(--primitive-alpha-yellow-5);
    --alpha-static-yellow-10: var(--primitive-alpha-yellow-10);
    --alpha-static-yellow-20: var(--primitive-alpha-yellow-20);
    --alpha-static-yellow-30: var(--primitive-alpha-yellow-30);
    --alpha-static-yellow-40: var(--primitive-alpha-yellow-40);
    --alpha-static-yellow-50: var(--primitive-alpha-yellow-50);
    --alpha-static-yellow-60: var(--primitive-alpha-yellow-60);
    --alpha-static-yellow-70: var(--primitive-alpha-yellow-70);
    --alpha-static-yellow-80: var(--primitive-alpha-yellow-80);
    --alpha-static-yellow-90: var(--primitive-alpha-yellow-90);
    --alpha-static-yellow-95: var(--primitive-alpha-yellow-95);
    --alpha-static-yellow-100: var(--primitive-alpha-yellow-100);
    --alpha-static-clearblue-0: var(--primitive-alpha-clearblue-0);
    --alpha-static-clearblue-5: var(--primitive-alpha-clearblue-5);
    --alpha-static-clearblue-10: var(--primitive-alpha-clearblue-10);
    --alpha-static-clearblue-20: var(--primitive-alpha-clearblue-20);
    --alpha-static-clearblue-30: var(--primitive-alpha-clearblue-30);
    --alpha-static-clearblue-40: var(--primitive-alpha-clearblue-40);
    --alpha-static-clearblue-50: var(--primitive-alpha-clearblue-50);
    --alpha-static-clearblue-60: var(--primitive-alpha-clearblue-60);
    --alpha-static-clearblue-70: var(--primitive-alpha-clearblue-70);
    --alpha-static-clearblue-80: var(--primitive-alpha-clearblue-80);
    --alpha-static-clearblue-90: var(--primitive-alpha-clearblue-90);
    --alpha-static-clearblue-95: var(--primitive-alpha-clearblue-95);
    --alpha-static-clearblue-100: var(--primitive-alpha-clearblue-100);
    --alpha-static-rosered-0: var(--primitive-alpha-rosered-0);
    --alpha-static-rosered-5: var(--primitive-alpha-rosered-5);
    --alpha-static-rosered-10: var(--primitive-alpha-rosered-10);
    --alpha-static-rosered-20: var(--primitive-alpha-rosered-20);
    --alpha-static-rosered-30: var(--primitive-alpha-rosered-30);
    --alpha-static-rosered-40: var(--primitive-alpha-rosered-40);
    --alpha-static-rosered-50: var(--primitive-alpha-rosered-50);
    --alpha-static-rosered-60: var(--primitive-alpha-rosered-60);
    --alpha-static-rosered-70: var(--primitive-alpha-rosered-70);
    --alpha-static-rosered-80: var(--primitive-alpha-rosered-80);
    --alpha-static-rosered-90: var(--primitive-alpha-rosered-90);
    --alpha-static-rosered-95: var(--primitive-alpha-rosered-95);
    --alpha-static-rosered-100: var(--primitive-alpha-rosered-100);
    --alpha-static-leaf-0: var(--primitive-alpha-leaf-0);
    --alpha-static-leaf-5: var(--primitive-alpha-leaf-5);
    --alpha-static-leaf-10: var(--primitive-alpha-leaf-10);
    --alpha-static-leaf-20: var(--primitive-alpha-leaf-20);
    --alpha-static-leaf-30: var(--primitive-alpha-leaf-30);
    --alpha-static-leaf-40: var(--primitive-alpha-leaf-40);
    --alpha-static-leaf-50: var(--primitive-alpha-leaf-50);
    --alpha-static-leaf-60: var(--primitive-alpha-leaf-60);
    --alpha-static-leaf-70: var(--primitive-alpha-leaf-70);
    --alpha-static-leaf-80: var(--primitive-alpha-leaf-80);
    --alpha-static-leaf-90: var(--primitive-alpha-leaf-90);
    --alpha-static-leaf-95: var(--primitive-alpha-leaf-95);
    --alpha-static-leaf-100: var(--primitive-alpha-leaf-100);
    --alpha-static-forest-0: var(--primitive-alpha-forest-0);
    --alpha-static-forest-5: var(--primitive-alpha-forest-5);
    --alpha-static-forest-10: var(--primitive-alpha-forest-10);
    --alpha-static-forest-20: var(--primitive-alpha-forest-20);
    --alpha-static-forest-30: var(--primitive-alpha-forest-30);
    --alpha-static-forest-40: var(--primitive-alpha-forest-40);
    --alpha-static-forest-50: var(--primitive-alpha-forest-50);
    --alpha-static-forest-60: var(--primitive-alpha-forest-60);
    --alpha-static-forest-70: var(--primitive-alpha-forest-70);
    --alpha-static-forest-80: var(--primitive-alpha-forest-80);
    --alpha-static-forest-90: var(--primitive-alpha-forest-90);
    --alpha-static-forest-95: var(--primitive-alpha-forest-95);
    --alpha-static-forest-100: var(--primitive-alpha-forest-100);
    --alpha-static-waterblue-0: var(--primitive-alpha-waterblue-0);
    --alpha-static-waterblue-5: var(--primitive-alpha-waterblue-5);
    --alpha-static-waterblue-10: var(--primitive-alpha-waterblue-10);
    --alpha-static-waterblue-20: var(--primitive-alpha-waterblue-20);
    --alpha-static-waterblue-30: var(--primitive-alpha-waterblue-30);
    --alpha-static-waterblue-40: var(--primitive-alpha-waterblue-40);
    --alpha-static-waterblue-50: var(--primitive-alpha-waterblue-50);
    --alpha-static-waterblue-60: var(--primitive-alpha-waterblue-60);
    --alpha-static-waterblue-70: var(--primitive-alpha-waterblue-70);
    --alpha-static-waterblue-80: var(--primitive-alpha-waterblue-80);
    --alpha-static-waterblue-90: var(--primitive-alpha-waterblue-90);
    --alpha-static-waterblue-95: var(--primitive-alpha-waterblue-95);
    --alpha-static-waterblue-100: var(--primitive-alpha-waterblue-100);
    --alpha-static-purple-0: var(--primitive-alpha-purple-0);
    --alpha-static-purple-5: var(--primitive-alpha-purple-5);
    --alpha-static-purple-10: var(--primitive-alpha-purple-10);
    --alpha-static-purple-20: var(--primitive-alpha-purple-20);
    --alpha-static-purple-30: var(--primitive-alpha-purple-30);
    --alpha-static-purple-40: var(--primitive-alpha-purple-40);
    --alpha-static-purple-50: var(--primitive-alpha-purple-50);
    --alpha-static-purple-60: var(--primitive-alpha-purple-60);
    --alpha-static-purple-70: var(--primitive-alpha-purple-70);
    --alpha-static-purple-80: var(--primitive-alpha-purple-80);
    --alpha-static-purple-90: var(--primitive-alpha-purple-90);
    --alpha-static-purple-95: var(--primitive-alpha-purple-95);
    --alpha-static-purple-100: var(--primitive-alpha-purple-100);
    --alpha-static-orange-0: var(--primitive-alpha-orange-0);
    --alpha-static-orange-5: var(--primitive-alpha-orange-5);
    --alpha-static-orange-10: var(--primitive-alpha-orange-10);
    --alpha-static-orange-20: var(--primitive-alpha-orange-20);
    --alpha-static-orange-30: var(--primitive-alpha-orange-30);
    --alpha-static-orange-40: var(--primitive-alpha-orange-40);
    --alpha-static-orange-50: var(--primitive-alpha-orange-50);
    --alpha-static-orange-60: var(--primitive-alpha-orange-60);
    --alpha-static-orange-70: var(--primitive-alpha-orange-70);
    --alpha-static-orange-80: var(--primitive-alpha-orange-80);
    --alpha-static-orange-90: var(--primitive-alpha-orange-90);
    --alpha-static-orange-95: var(--primitive-alpha-orange-95);
    --alpha-static-orange-100: var(--primitive-alpha-orange-100);
    --alpha-static-magenta-0: var(--primitive-alpha-magenta-0);
    --alpha-static-magenta-5: var(--primitive-alpha-magenta-5);
    --alpha-static-magenta-10: var(--primitive-alpha-magenta-10);
    --alpha-static-magenta-20: var(--primitive-alpha-magenta-20);
    --alpha-static-magenta-30: var(--primitive-alpha-magenta-30);
    --alpha-static-magenta-40: var(--primitive-alpha-magenta-40);
    --alpha-static-magenta-50: var(--primitive-alpha-magenta-50);
    --alpha-static-magenta-60: var(--primitive-alpha-magenta-60);
    --alpha-static-magenta-70: var(--primitive-alpha-magenta-70);
    --alpha-static-magenta-80: var(--primitive-alpha-magenta-80);
    --alpha-static-magenta-90: var(--primitive-alpha-magenta-90);
    --alpha-static-magenta-95: var(--primitive-alpha-magenta-95);
    --alpha-static-magenta-100: var(--primitive-alpha-magenta-100);
    --alpha-static-brand-0: var(--primitive-alpha-brand-0);
    --alpha-static-brand-5: var(--primitive-alpha-brand-5);
    --alpha-static-brand-10: var(--primitive-alpha-brand-10);
    --alpha-static-brand-20: var(--primitive-alpha-brand-20);
    --alpha-static-brand-30: var(--primitive-alpha-brand-30);
    --alpha-static-brand-40: var(--primitive-alpha-brand-40);
    --alpha-static-brand-50: var(--primitive-alpha-brand-50);
    --alpha-static-brand-60: var(--primitive-alpha-brand-60);
    --alpha-static-brand-70: var(--primitive-alpha-brand-70);
    --alpha-static-brand-80: var(--primitive-alpha-brand-80);
    --alpha-static-brand-90: var(--primitive-alpha-brand-90);
    --alpha-static-brand-95: var(--primitive-alpha-brand-95);
    --alpha-static-brand-100: var(--primitive-alpha-brand-100);
    --alpha-static-success-0: var(--primitive-alpha-success-0);
    --alpha-static-success-5: var(--primitive-alpha-success-5);
    --alpha-static-success-10: var(--primitive-alpha-success-10);
    --alpha-static-success-20: var(--primitive-alpha-success-20);
    --alpha-static-success-30: var(--primitive-alpha-success-30);
    --alpha-static-success-40: var(--primitive-alpha-success-40);
    --alpha-static-success-50: var(--primitive-alpha-success-50);
    --alpha-static-success-60: var(--primitive-alpha-success-60);
    --alpha-static-success-70: var(--primitive-alpha-success-70);
    --alpha-static-success-80: var(--primitive-alpha-success-80);
    --alpha-static-success-90: var(--primitive-alpha-success-90);
    --alpha-static-success-95: var(--primitive-alpha-success-95);
    --alpha-static-success-100: var(--primitive-alpha-success-100);
    --alpha-static-warning-0: var(--primitive-alpha-warning-0);
    --alpha-static-warning-5: var(--primitive-alpha-warning-5);
    --alpha-static-warning-10: var(--primitive-alpha-warning-10);
    --alpha-static-warning-20: var(--primitive-alpha-warning-20);
    --alpha-static-warning-30: var(--primitive-alpha-warning-30);
    --alpha-static-warning-40: var(--primitive-alpha-warning-40);
    --alpha-static-warning-50: var(--primitive-alpha-warning-50);
    --alpha-static-warning-60: var(--primitive-alpha-warning-60);
    --alpha-static-warning-70: var(--primitive-alpha-warning-70);
    --alpha-static-warning-80: var(--primitive-alpha-warning-80);
    --alpha-static-warning-90: var(--primitive-alpha-warning-90);
    --alpha-static-warning-95: var(--primitive-alpha-warning-95);
    --alpha-static-warning-100: var(--primitive-alpha-warning-100);
    --alpha-static-error-0: var(--primitive-alpha-error-0);
    --alpha-static-error-5: var(--primitive-alpha-error-5);
    --alpha-static-error-10: var(--primitive-alpha-error-10);
    --alpha-static-error-20: var(--primitive-alpha-error-20);
    --alpha-static-error-30: var(--primitive-alpha-error-30);
    --alpha-static-error-40: var(--primitive-alpha-error-40);
    --alpha-static-error-50: var(--primitive-alpha-error-50);
    --alpha-static-error-60: var(--primitive-alpha-error-60);
    --alpha-static-error-70: var(--primitive-alpha-error-70);
    --alpha-static-error-80: var(--primitive-alpha-error-80);
    --alpha-static-error-90: var(--primitive-alpha-error-90);
    --alpha-static-error-95: var(--primitive-alpha-error-95);
    --alpha-static-error-100: var(--primitive-alpha-error-100);
    --semantic-text-primary: var(--primitive-primary-grey-900);
    --semantic-text-secondary: var(--primitive-primary-grey-700);
    --semantic-text-primary_hover: var(--primitive-primary-grey-800);
    --semantic-text-secondary_hover: var(--primitive-primary-grey-800);
    --semantic-text-tertiary: var(--primitive-primary-grey-600);
    --semantic-text-tertiary_hover: var(--primitive-primary-grey-700);
    --semantic-text-quaternary: var(--primitive-primary-grey-500);
    --semantic-text-white: var(--tw-white);
    --semantic-text-disabled: var(--primitive-primary-grey-400);
    --semantic-text-placeholder: var(--primitive-primary-grey-400);
    --semantic-text-placeholder-subtle: var(--primitive-primary-grey-300);
    --semantic-text-brand-primary: var(--primitive-primary-brand-600);
    --semantic-text-warning-primary: var(--primitive-primary-warning-600);
    --semantic-text-success-primary: var(--primitive-primary-success-600);
    --semantic-text-primary_pressed: var(--primitive-primary-grey-700);
    --semantic-text-primary-reverse: var(--primitive-primary-grey-50);
    --semantic-text-primary-reverse_hover: var(--primitive-primary-grey-100);
    --semantic-text-primary-reverse_pressed: var(--primitive-primary-grey-200);
    --semantic-text-error-primary: var(--primitive-primary-error-600);
    --semantic-text-secondary_pressed: var(--primitive-primary-grey-900);
    --semantic-text-brand-primary_hover: var(--primitive-primary-brand-700);
    --semantic-text-brand-primary_pressed: var(--primitive-primary-brand-800);
    --semantic-border-primary: var(--primitive-primary-grey-300);
    --semantic-border-secondary: var(--primitive-primary-grey-200);
    --semantic-border-tertiary: var(--primitive-primary-grey-100);
    --semantic-border-disabled: var(--primitive-primary-grey-300);
    --semantic-border-disabled_subtle: var(--primitive-primary-grey-200);
    --semantic-border-brand: var(--primitive-primary-brand-300);
    --semantic-border-brand-solid: var(--primitive-primary-brand-600);
    --semantic-border-error: var(--primitive-primary-error-300);
    --semantic-border-error-solid: var(--primitive-primary-error-600);
    --semantic-border-success-solid: var(--primitive-primary-success-600);
    --semantic-border-success: var(--primitive-primary-success-300);
    --semantic-border-primary_solid: var(--primitive-primary-grey-900);
    --semantic-fg-primary: var(--primitive-primary-grey-900);
    --semantic-fg-secondary: var(--primitive-primary-grey-700);
    --semantic-fg-secondary_hover: var(--primitive-primary-grey-800);
    --semantic-fg-tertiary: var(--primitive-primary-grey-600);
    --semantic-fg-tertiary_hover: var(--primitive-primary-grey-700);
    --semantic-fg-quaternary: var(--primitive-primary-grey-500);
    --semantic-fg-quaternary_hover: var(--primitive-primary-grey-600);
    --semantic-fg-quinary: var(--primitive-primary-grey-400);
    --semantic-fg-quinary_hover: var(--primitive-primary-grey-500);
    --semantic-fg-senary: var(--primitive-primary-grey-300);
    --semantic-fg-white: var(--tw-white);
    --semantic-fg-disabled: var(--primitive-primary-grey-400);
    --semantic-fg-disabled_subtle: var(--primitive-primary-grey-200);
    --semantic-fg-brand-primary: var(--primitive-primary-brand-600);
    --semantic-fg-brand-primary_alt: var(--primitive-primary-brand-600);
    --semantic-fg-error-primary: var(--primitive-primary-error-600);
    --semantic-fg-error-secondary: var(--primitive-primary-error-500);
    --semantic-fg-warning-primary: var(--primitive-primary-warning-600);
    --semantic-fg-warning-secondary: var(--primitive-primary-warning-500);
    --semantic-fg-success-primary: var(--primitive-primary-success-600);
    --semantic-fg-success-secondary: var(--primitive-primary-success-500);
    --semantic-fg-primary_hover: var(--primitive-primary-grey-800);
    --semantic-fg-primary_pressed: var(--primitive-primary-grey-700);
    --semantic-fg-primary-reverse: var(--primitive-primary-grey-50);
    --semantic-fg-primary-reverse_hover: var(--primitive-primary-grey-100);
    --semantic-fg-primary-reverse_pressed: var(--primitive-primary-grey-200);
    --semantic-fg-brand-primary_hover: var(--primitive-primary-brand-700);
    --semantic-fg-brand-primary_pressed: var(--primitive-primary-brand-800);
    --semantic-fg-placeholder: var(--primitive-primary-grey-500);
    --semantic-bg-primary: var(--primitive-primary-grey-25);
    --semantic-bg-primary_alt: var(--primitive-primary-grey-25);
    --semantic-bg-primary-solid: var(--primitive-primary-grey-950);
    --semantic-bg-secondary: var(--primitive-primary-grey-50);
    --semantic-bg-secondary_alt: var(--primitive-primary-grey-50);
    --semantic-bg-secondary_hover: var(--primitive-primary-grey-100);
    --semantic-bg-tertiary: var(--primitive-primary-grey-100);
    --semantic-bg-tertiary_hover: var(--primitive-primary-grey-200);
    --semantic-bg-quaternary: var(--primitive-primary-grey-200);
    --semantic-bg-active: var(--primitive-primary-grey-50);
    --semantic-bg-disabled: var(--primitive-primary-grey-100);
    --semantic-bg-disabled_subtle: var(--primitive-primary-grey-50);
    --semantic-bg-overlay: var(--primitive-alpha-black-70);
    --semantic-bg-brand-primary: var(--primitive-primary-brand-50);
    --semantic-bg-brand-primary_alt: var(--primitive-primary-brand-50);
    --semantic-bg-brand-primary-solid: var(--primitive-primary-brand-600);
    --semantic-bg-error-primary: var(--primitive-primary-error-50);
    --semantic-bg-error-secondary: var(--primitive-primary-error-100);
    --semantic-bg-error-solid: var(--primitive-primary-error-600);
    --semantic-bg-warning-primary: var(--primitive-primary-warning-50);
    --semantic-bg-warning-secondary: var(--primitive-primary-warning-100);
    --semantic-bg-warning-solid: var(--primitive-primary-warning-600);
    --semantic-bg-success-primary: var(--primitive-primary-success-50);
    --semantic-bg-success-secondary: var(--primitive-primary-success-100);
    --semantic-bg-success-solid: var(--primitive-primary-success-600);
    --semantic-bg-white: var(--tw-white);
    --semantic-bg-tertiary_alt: var(--primitive-primary-grey-100);
    --semantic-bg-primary_hover: var(--primitive-primary-grey-100);
    --semantic-bg-primary_pressed: var(--primitive-primary-grey-200);
    --semantic-bg-primary-reverse: var(--primitive-primary-grey-950);
    --semantic-bg-primary-reverse_hover: var(--primitive-primary-grey-800);
    --semantic-bg-primary-reverse_pressed: var(--primitive-primary-grey-700);
    --semantic-bg-secondary_pressed: var(--primitive-primary-grey-200);
    --semantic-bg-tertiary_pressed: var(--primitive-primary-grey-300);
    --semantic-bg-primary-solid_hover: var(--primitive-primary-grey-800);
    --semantic-quest-text-gem: var(--utility-color-clearblue-600);
    --semantic-quest-text-gem-static: var(--utility-color-static-clearblue-600);
    --semantic-quest-text-heart: var(--utility-color-rosered-600);
    --semantic-quest-text-heart-static: var(--utility-color-static-rosered-600);
    --semantic-quest-text-xp: var(--utility-color-forest-700);
    --semantic-quest-text-xp-static: var(--utility-color-static-forest-700);
    --semantic-quest-text-gem-reverse: var(--utility-color-clearblue-25);
    --semantic-quest-text-heart-reverse: var(--utility-color-rosered-25);
    --semantic-quest-text-xp-reverse: var(--utility-color-forest-25);
    --semantic-quest-text-fire: var(--utility-color-orange-700);
    --semantic-quest-text-fire-reverse: var(--utility-color-orange-25);
    --semantic-quest-text-fire-static: var(--utility-color-static-orange-600);
    --semantic-quest-text-quest-reverse: var(--primitive-primary-grey-25);
    --semantic-quest-text-quest-static: var(--primitive-primary-grey-25);
    --semantic-quest-text-quest: var(--primitive-primary-grey-700);
    --semantic-quest-bg-gem: var(--primitive-alpha-clearblue-10);
    --semantic-quest-bg-heart: var(--primitive-alpha-rosered-10);
    --semantic-quest-bg-xp: var(--primitive-alpha-forest-10);
    --semantic-quest-bg-fire: var(--primitive-alpha-orange-10);
    --semantic-quest-bg-quest: var(--primitive-alpha-black-5);
    --component-navigation-bg-primary: var(--primitive-alpha-grey950-100);
    --component-navigation-border-default: var(--semantic-border-primary);
    --component-button-primary-fg-white_default: var(--semantic-fg-primary-reverse);
    --component-button-primary-fg-disabled: var(--semantic-fg-disabled);
    --component-button-primary-fg-white_hover: var(--semantic-fg-primary-reverse_hover);
    --component-button-primary-fg-white_pressed: var(--semantic-fg-primary-reverse_pressed);
    --component-button-primary-fg-brand-light_default: var(--semantic-fg-brand-primary);
    --component-button-primary-fg-brand-light_hover: var(--semantic-fg-brand-primary_hover);
    --component-button-primary-fg-brand-light_pressed: var(--semantic-fg-brand-primary_pressed);
    --component-button-primary-fg-black_default: var(--semantic-fg-primary);
    --component-button-primary-fg-black_hover: var(--semantic-fg-primary_hover);
    --component-button-primary-fg-black_pressed: var(--semantic-fg-primary_pressed);
    --component-button-primary-bg-grey-dark_default: var(--semantic-bg-primary-reverse);
    --component-button-primary-bg-grey-dark_hover: var(--semantic-bg-primary-reverse_hover);
    --component-button-primary-bg-grey-dark_pressed: var(--semantic-bg-primary-reverse_pressed);
    --component-button-primary-bg-disabled: var(--semantic-bg-disabled);
    --component-button-primary-bg-grey-light_default: var(--semantic-bg-tertiary);
    --component-button-primary-bg-grey-light_hover: var(--semantic-bg-tertiary_hover);
    --component-button-primary-bg-grey-light_pressed: var(--semantic-bg-tertiary_pressed);
    --component-button-primary-bg-brand-light_hover: var(--primitive-primary-brand-50);
    --component-button-primary-bg-brand-light_pressed: var(--primitive-primary-brand-100);
    --component-button-primary-bg-brand-dark_default: var(--primitive-primary-brand-600);
    --component-button-primary-bg-brand-dark_hover: var(--primitive-primary-brand-700);
    --component-button-primary-bg-brand-dark_pressed: var(--primitive-primary-brand-800);
    --component-button-primary-bg-success-dark_default: var(--primitive-primary-success-600);
    --component-button-primary-bg-success-dark_hover: var(--primitive-primary-success-700);
    --component-button-primary-bg-success-dark_pressed: var(--primitive-primary-success-800);
    --component-button-primary-border-grey-light: var(--semantic-border-primary);
    --component-button-primary-border-disabled: var(--semantic-border-disabled);
    --component-button-primary-border-brand-light: var(--semantic-border-brand);
    --component-button-primary-text-white_default: var(--semantic-text-primary-reverse);
    --component-button-primary-text-white_hover: var(--semantic-text-primary-reverse_hover);
    --component-button-primary-text-white_pressed: var(--semantic-text-primary-reverse_pressed);
    --component-button-primary-text-black_default: var(--semantic-text-primary);
    --component-button-primary-text-black_hover: var(--semantic-text-primary_hover);
    --component-button-primary-text-black_pressed: var(--semantic-text-primary_pressed);
    --component-button-primary-text-disabled: var(--semantic-text-disabled);
    --component-button-primary-text-brand-light_default: var(--semantic-text-brand-primary);
    --component-button-primary-text-brand-light_hover: var(--semantic-text-brand-primary_hover);
    --component-button-primary-text-brand-light_pressed: var(--semantic-text-brand-primary_pressed);
    --component-button-color-bg-leaf-light_default: var(--primitive-secondary-leaf-50);
    --component-button-color-bg-leaf-light_hover: var(--primitive-secondary-leaf-100);
    --component-button-color-bg-leaf-light_pressed: var(--primitive-secondary-leaf-200);
    --component-button-color-bg-leaf-dark_default: var(--primitive-secondary-leaf-700);
    --component-button-color-bg-leaf-dark_hover: var(--primitive-secondary-leaf-600);
    --component-button-color-bg-leaf-dark_pressed: var(--primitive-secondary-leaf-500);
    --component-button-color-bg-forest-dark_default: var(--primitive-secondary-forest-700);
    --component-button-color-bg-forest-dark_hover: var(--primitive-secondary-forest-600);
    --component-button-color-bg-forest-dark_pressed: var(--primitive-secondary-forest-500);
    --component-button-color-bg-forest-light_default: var(--primitive-secondary-forest-50);
    --component-button-color-bg-forest-light_hover: var(--primitive-secondary-forest-100);
    --component-button-color-bg-forest-light_pressed: var(--primitive-secondary-forest-200);
    --component-button-color-bg-waterblue-light_default: var(--primitive-secondary-waterblue-50);
    --component-button-color-bg-waterblue-light_hover: var(--primitive-secondary-waterblue-100);
    --component-button-color-bg-waterblue-light_pressed: var(--primitive-secondary-waterblue-200);
    --component-button-color-bg-waterblue-dark_default: var(--primitive-secondary-waterblue-700);
    --component-button-color-bg-waterblue-dark_hover: var(--primitive-secondary-waterblue-600);
    --component-button-color-bg-waterblue-dark_pressed: var(--primitive-secondary-waterblue-500);
    --component-button-color-bg-clearblue-light_default: var(--primitive-secondary-clearblue-50);
    --component-button-color-bg-clearblue-light_hover: var(--primitive-secondary-clearblue-100);
    --component-button-color-bg-clearblue-light_pressed: var(--primitive-secondary-clearblue-200);
    --component-button-color-bg-clearblue-dark_default: var(--primitive-secondary-clearblue-600);
    --component-button-color-bg-clearblue-dark_hover: var(--primitive-secondary-clearblue-500);
    --component-button-color-bg-clearblue-dark_pressed: var(--primitive-secondary-clearblue-400);
    --component-button-color-bg-purple-light_default: var(--primitive-secondary-purple-50);
    --component-button-color-bg-purple-light_hover: var(--primitive-secondary-purple-100);
    --component-button-color-bg-purple-light_pressed: var(--primitive-secondary-purple-200);
    --component-button-color-bg-purple-dark_default: var(--primitive-secondary-purple-600);
    --component-button-color-bg-purple-dark_hover: var(--primitive-secondary-purple-500);
    --component-button-color-bg-purple-dark_pressed: var(--primitive-secondary-purple-400);
    --component-button-color-bg-magenta-light_default: var(--primitive-secondary-magenta-50);
    --component-button-color-bg-magenta-light_hover: var(--primitive-secondary-magenta-100);
    --component-button-color-bg-magenta-light_pressed: var(--primitive-secondary-magenta-200);
    --component-button-color-bg-magenta-dark_default: var(--primitive-secondary-magenta-600);
    --component-button-color-bg-magenta-dark_hover: var(--primitive-secondary-magenta-500);
    --component-button-color-bg-magenta-dark_pressed: var(--primitive-secondary-magenta-400);
    --component-button-color-bg-rosered-light_default: var(--primitive-secondary-rosered-50);
    --component-button-color-bg-rosered-light_hover: var(--primitive-secondary-rosered-100);
    --component-button-color-bg-rosered-light_pressed: var(--primitive-secondary-rosered-200);
    --component-button-color-bg-rosered-dark_default: var(--primitive-secondary-rosered-500);
    --component-button-color-bg-rosered-dark_hover: var(--primitive-secondary-rosered-400);
    --component-button-color-bg-rosered-dark_pressed: var(--primitive-secondary-rosered-300);
    --component-button-color-bg-orange-light_default: var(--primitive-secondary-orange-50);
    --component-button-color-bg-orange-light_hover: var(--primitive-secondary-orange-100);
    --component-button-color-bg-orange-light_pressed: var(--primitive-secondary-orange-200);
    --component-button-color-bg-orange-dark_default: var(--primitive-secondary-orange-600);
    --component-button-color-bg-orange-dark_hover: var(--primitive-secondary-orange-500);
    --component-button-color-bg-orange-dark_pressed: var(--primitive-secondary-orange-400);
    --component-button-color-bg-yellow-light_default: var(--primitive-secondary-yellow-50);
    --component-button-color-bg-yellow-light_hover: var(--primitive-secondary-yellow-100);
    --component-button-color-bg-yellow-light_pressed: var(--primitive-secondary-yellow-200);
    --component-button-color-bg-yellow-dark_default: var(--primitive-secondary-yellow-600);
    --component-button-color-bg-yellow-dark_hover: var(--primitive-secondary-yellow-500);
    --component-button-color-bg-yellow-dark_pressed: var(--primitive-secondary-yellow-400);
    --component-button-color-fg-leaf-light_default: var(--primitive-secondary-leaf-700);
    --component-button-color-fg-leaf-light_hover: var(--primitive-secondary-leaf-600);
    --component-button-color-fg-leaf-light_pressed: var(--primitive-secondary-leaf-600);
    --component-button-color-fg-leaf-dark_default: var(--primitive-secondary-leaf-50);
    --component-button-color-fg-leaf-dark_hover: var(--primitive-secondary-leaf-100);
    --component-button-color-fg-leaf-dark_pressed: var(--primitive-secondary-leaf-100);
    --component-button-color-fg-forest-dark_default: var(--primitive-secondary-forest-25);
    --component-button-color-fg-forest-dark_hover: var(--primitive-secondary-forest-50);
    --component-button-color-fg-forest-dark_pressed: var(--primitive-secondary-forest-50);
    --component-button-color-fg-forest-light_default: var(--primitive-secondary-forest-700);
    --component-button-color-fg-forest-light_hover: var(--primitive-secondary-forest-600);
    --component-button-color-fg-forest-light_pressed: var(--primitive-secondary-forest-600);
    --component-button-color-fg-waterblue-light_default: var(--primitive-secondary-waterblue-700);
    --component-button-color-fg-waterblue-light_hover: var(--primitive-secondary-waterblue-600);
    --component-button-color-fg-waterblue-light_pressed: var(--primitive-secondary-waterblue-600);
    --component-button-color-fg-waterblue-dark_default: var(--primitive-secondary-waterblue-25);
    --component-button-color-fg-waterblue-dark_hover: var(--primitive-secondary-waterblue-50);
    --component-button-color-fg-waterblue-dark_pressed: var(--primitive-secondary-waterblue-50);
    --component-button-color-fg-clearblue-light_default: var(--primitive-secondary-clearblue-600);
    --component-button-color-fg-clearblue-light_hover: var(--primitive-secondary-clearblue-600);
    --component-button-color-fg-clearblue-light_pressed: var(--primitive-secondary-clearblue-700);
    --component-button-color-fg-clearblue-dark_default: var(--primitive-secondary-clearblue-25);
    --component-button-color-fg-clearblue-dark_hover: var(--primitive-secondary-clearblue-50);
    --component-button-color-fg-clearblue-dark_pressed: var(--primitive-secondary-clearblue-50);
    --component-button-color-fg-purple-light_default: var(--primitive-secondary-purple-600);
    --component-button-color-fg-purple-light_hover: var(--primitive-secondary-purple-600);
    --component-button-color-fg-purple-light_pressed: var(--primitive-secondary-purple-700);
    --component-button-color-fg-purple-dark_default: var(--primitive-secondary-purple-25);
    --component-button-color-fg-purple-dark_hover: var(--primitive-secondary-purple-50);
    --component-button-color-fg-purple-dark_pressed: var(--primitive-secondary-purple-50);
    --component-button-color-fg-magenta-light_default: var(--primitive-secondary-magenta-600);
    --component-button-color-fg-magenta-light_hover: var(--primitive-secondary-magenta-600);
    --component-button-color-fg-magenta-light_pressed: var(--primitive-secondary-magenta-700);
    --component-button-color-fg-magenta-dark_default: var(--primitive-secondary-magenta-25);
    --component-button-color-fg-magenta-dark_hover: var(--primitive-secondary-magenta-50);
    --component-button-color-fg-magenta-dark_pressed: var(--primitive-secondary-magenta-50);
    --component-button-color-fg-rosered-light_default: var(--primitive-secondary-rosered-600);
    --component-button-color-fg-rosered-light_hover: var(--primitive-secondary-rosered-600);
    --component-button-color-fg-rosered-light_pressed: var(--primitive-secondary-rosered-700);
    --component-button-color-fg-rosered-dark_default: var(--primitive-secondary-rosered-25);
    --component-button-color-fg-rosered-dark_hover: var(--primitive-secondary-rosered-50);
    --component-button-color-fg-rosered-dark_pressed: var(--primitive-secondary-rosered-50);
    --component-button-color-fg-orange-light_default: var(--primitive-secondary-orange-600);
    --component-button-color-fg-orange-light_hover: var(--primitive-secondary-orange-600);
    --component-button-color-fg-orange-light_pressed: var(--primitive-secondary-orange-700);
    --component-button-color-fg-orange-dark_default: var(--primitive-secondary-orange-25);
    --component-button-color-fg-orange-dark_hover: var(--primitive-secondary-orange-50);
    --component-button-color-fg-orange-dark_pressed: var(--primitive-secondary-orange-50);
    --component-button-color-fg-yellow-light_default: var(--primitive-secondary-yellow-700);
    --component-button-color-fg-yellow-light_hover: var(--primitive-secondary-yellow-700);
    --component-button-color-fg-yellow-light_pressed: var(--primitive-secondary-yellow-800);
    --component-button-color-fg-yellow-dark_default: var(--primitive-secondary-yellow-25);
    --component-button-color-fg-yellow-dark_hover: var(--primitive-secondary-yellow-50);
    --component-button-color-fg-yellow-dark_pressed: var(--primitive-secondary-yellow-50);
    --component-button-color-text-leaf-light_default: var(--primitive-secondary-leaf-700);
    --component-button-color-text-leaf-light_hover: var(--primitive-secondary-leaf-600);
    --component-button-color-text-leaf-light_pressed: var(--primitive-secondary-leaf-600);
    --component-button-color-text-leaf-dark_default: var(--primitive-secondary-leaf-50);
    --component-button-color-text-leaf-dark_hover: var(--primitive-secondary-leaf-100);
    --component-button-color-text-leaf-dark_pressed: var(--primitive-secondary-leaf-100);
    --component-button-color-text-forest-light_default: var(--primitive-secondary-forest-700);
    --component-button-color-text-forest-light_hover: var(--primitive-secondary-forest-600);
    --component-button-color-text-forest-light_pressed: var(--primitive-secondary-forest-600);
    --component-button-color-text-forest-dark_default: var(--primitive-secondary-forest-25);
    --component-button-color-text-forest-dark_hover: var(--primitive-secondary-forest-50);
    --component-button-color-text-forest-dark_pressed: var(--primitive-secondary-forest-50);
    --component-button-color-text-waterblue-light_default: var(--primitive-secondary-waterblue-700);
    --component-button-color-text-waterblue-light_hover: var(--primitive-secondary-waterblue-600);
    --component-button-color-text-waterblue-light_pressed: var(--primitive-secondary-waterblue-600);
    --component-button-color-text-waterblue-dark_default: var(--primitive-secondary-waterblue-25);
    --component-button-color-text-waterblue-dark_hover: var(--primitive-secondary-waterblue-50);
    --component-button-color-text-waterblue-dark_pressed: var(--primitive-secondary-waterblue-50);
    --component-button-color-text-clearblue-light_default: var(--primitive-secondary-clearblue-600);
    --component-button-color-text-clearblue-light_hover: var(--primitive-secondary-clearblue-600);
    --component-button-color-text-clearblue-light_pressed: var(--primitive-secondary-clearblue-700);
    --component-button-color-text-clearblue-dark_default: var(--primitive-secondary-clearblue-25);
    --component-button-color-text-clearblue-dark_hover: var(--primitive-secondary-clearblue-50);
    --component-button-color-text-clearblue-dark_pressed: var(--primitive-secondary-clearblue-50);
    --component-button-color-text-purple-light_default: var(--primitive-secondary-purple-600);
    --component-button-color-text-purple-light_hover: var(--primitive-secondary-purple-600);
    --component-button-color-text-purple-light_pressed: var(--primitive-secondary-purple-700);
    --component-button-color-text-purple-dark_default: var(--primitive-secondary-purple-25);
    --component-button-color-text-purple-dark_hover: var(--primitive-secondary-purple-50);
    --component-button-color-text-purple-dark_pressed: var(--primitive-secondary-purple-50);
    --component-button-color-text-magenta-light_default: var(--primitive-secondary-magenta-600);
    --component-button-color-text-magenta-light_hover: var(--primitive-secondary-magenta-600);
    --component-button-color-text-magenta-light_pressed: var(--primitive-secondary-magenta-700);
    --component-button-color-text-magenta-dark_default: var(--primitive-secondary-magenta-25);
    --component-button-color-text-magenta-dark_hover: var(--primitive-secondary-magenta-50);
    --component-button-color-text-magenta-dark_pressed: var(--primitive-secondary-magenta-50);
    --component-button-color-text-rosered-light_default: var(--primitive-secondary-rosered-600);
    --component-button-color-text-rosered-light_hover: var(--primitive-secondary-rosered-600);
    --component-button-color-text-rosered-light_pressed: var(--primitive-secondary-rosered-700);
    --component-button-color-text-rosered-dark_default: var(--primitive-secondary-rosered-25);
    --component-button-color-text-rosered-dark_hover: var(--primitive-secondary-rosered-50);
    --component-button-color-text-rosered-dark_pressed: var(--primitive-secondary-rosered-50);
    --component-button-color-text-orange-light_default: var(--primitive-secondary-orange-600);
    --component-button-color-text-orange-light_hover: var(--primitive-secondary-orange-600);
    --component-button-color-text-orange-light_pressed: var(--primitive-secondary-orange-700);
    --component-button-color-text-orange-dark_default: var(--primitive-secondary-orange-25);
    --component-button-color-text-orange-dark_hover: var(--primitive-secondary-orange-50);
    --component-button-color-text-orange-dark_pressed: var(--primitive-secondary-orange-50);
    --component-button-color-text-yellow-light_default: var(--primitive-secondary-yellow-700);
    --component-button-color-text-yellow-light_hover: var(--primitive-secondary-yellow-700);
    --component-button-color-text-yellow-light_pressed: var(--primitive-secondary-yellow-800);
    --component-button-color-text-yellow-dark_default: var(--primitive-secondary-yellow-25);
    --component-button-color-text-yellow-dark_hover: var(--primitive-secondary-yellow-50);
    --component-button-color-text-yellow-dark_pressed: var(--primitive-secondary-yellow-50);
    --component-button-close-bg-hover: var(--primitive-alpha-black-5);
    --component-button-close-bg-pressed: var(--primitive-alpha-black-10);
    --component-button-close-fg-default: var(--primitive-alpha-black-50);
    --component-drawer-bg: var(--alpha-static-black-40);
    --component-rate-star-fg-active: var(--utility-color-yellow-500);
    --component-rate-star-fg-inactive: var(--semantic-fg-disabled_subtle);
    --component-input-field-text-placeholder: var(--semantic-text-placeholder);
    --component-input-field-text-default: var(--semantic-text-primary);
    --component-input-field-text-error: var(--semantic-text-error-primary);
    --component-input-field-text-disabled: var(--semantic-text-disabled);
    --component-input-field-text-success: var(--semantic-text-success-primary);
    --component-input-field-bg-default: var(--semantic-bg-secondary);
    --component-input-field-bg-disabled: var(--semantic-bg-disabled);
    --component-input-field-fg-default: var(--semantic-fg-primary);
    --component-input-field-fg-placeholder: var(--semantic-fg-placeholder);
    --component-input-field-fg-error: var(--semantic-fg-error-secondary);
    --component-input-field-fg-success: var(--semantic-fg-success-secondary);
    --component-input-field-fg-disabled: var(--semantic-fg-disabled);
    --component-input-field-border-default: var(--semantic-border-primary);
    --component-input-field-border-error: var(--semantic-border-error-solid);
    --component-input-field-border-success: var(--semantic-border-success-solid);
    --component-input-field-border-disabled: var(--semantic-border-disabled_subtle);
    --component-input-field-border-active: var(--semantic-border-primary_solid);
    --component-input-label-text-default: var(--semantic-text-primary);
    --component-input-label-text-secondary: var(--semantic-text-placeholder);
    --component-input-label-text-error: var(--semantic-text-error-primary);
    --component-input-label-text-success: var(--semantic-text-success-primary);
    --component-input-label-text-disabled: var(--semantic-text-disabled);
    --component-divider-primary: var(--semantic-border-primary);
    --component-divider-secondary: var(--semantic-border-secondary);
    --component-divider-tertiary: var(--semantic-border-tertiary);
    --component-toggle-bg-inactive: var(--semantic-bg-tertiary);
    --component-toggle-bg-inactive_hover: var(--semantic-bg-tertiary_hover);
    --component-toggle-bg-disabled: var(--semantic-bg-disabled);
    --component-toggle-bg-active: var(--semantic-bg-primary-solid);
    --component-toggle-bg-active_hover: var(--semantic-bg-primary-solid_hover);
    --component-toggle-fg-default: var(--semantic-fg-white);
    --component-toggle-fg-disabled: var(--semantic-fg-disabled_subtle);
    --component-tag-bg-default: var(--semantic-bg-primary);
    --component-tag-bg-light: var(--semantic-bg-tertiary);
    --component-tag-bg-dark: var(--semantic-bg-primary-reverse);
    --component-tag-bg-positive: var(--alpha-static-success-10);
    --component-tag-bg-negative: var(--alpha-static-error-10);
    --component-tag-text-default: var(--semantic-text-primary);
    --component-tag-text-reverse: var(--semantic-text-primary-reverse);
    --component-tag-text-positive: var(--semantic-text-success-primary);
    --component-tag-text-negative: var(--semantic-text-error-primary);
    --component-tag-border-default: var(--semantic-border-primary);
    --component-tag-fg-default: var(--semantic-fg-primary);
    --component-tag-fg-reverse: var(--semantic-fg-primary-reverse);
    --component-tag-fg-positive: var(--semantic-fg-success-primary);
    --component-tag-fg-negative: var(--semantic-fg-error-primary);
  }
}

body {
  @apply text-semantic-text-primary;
}
