.cube-loading-grid {
  width: 100px;
  height: 100px;
  margin: 100px auto;
}

.cube-loading-grid .cube-loading {
  width: 33%;
  height: 33%;
  background-color: #7390ff;
  border-color: 1px solid #f5f5f5;
  float: left;
  -webkit-animation: cubeGridScaleDelay 1.3s infinite ease-in-out;
  animation: cubeGridScaleDelay 1.3s infinite ease-in-out;
}
.cube-loading-grid .cube-loading1 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
.cube-loading-grid .cube-loading2 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.cube-loading-grid .cube-loading3 {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}
.cube-loading-grid .cube-loading4 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}
.cube-loading-grid .cube-loading5 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
.cube-loading-grid .cube-loading6 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.cube-loading-grid .cube-loading7 {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
.cube-loading-grid .cube-loading8 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}
.cube-loading-grid .cube-loading9 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

@-webkit-keyframes cubeGridScaleDelay {
  0%,
  70%,
  100% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  }
  35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1);
  }
}

@keyframes cubeGridScaleDelay {
  0%,
  70%,
  100% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  }
  35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1);
  }
}
