/* override antd styles below */

.AppEntryLayout .ant-layout-header {
  height: fit-content !important;
  padding: 0 !important;
}

.AppEntryLayout .AppHeader .ant-col {
  display: flex;
  align-items: center;
}

.AppEntryLayout .ant-drawer-body a {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.85);
}

.AppLayout .sidebar .ant-menu-item:not(.ant-popover-inner-content *) {
  margin: 0 !important;
  /* padding: 0 20px !important; */

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: var(--gray-1);

  display: flex;
  align-items: center;
}

.AppLayout .sidebar .ant-menu-item-only-child {
  padding-left: 50px !important;
}

.ant-menu-submenu-title {
  padding: 0 20px !important;
}

.AppLayout .ant-layout-sider-collapsed .ant-menu-submenu-title:not(.ant-popover-inner-content *),
.AppLayout .ant-layout-sider-collapsed .ant-menu-item:not(.ant-popover-inner-content *) {
  padding-left: 30px !important;
  align-items: center;
}

.AppLayout .ant-layout-sider-collapsed .ant-menu-submenu-title > span > svg,
.AppLayout .ant-layout-sider-collapsed .ant-menu-item > span > svg {
  width: 20px;
  height: 20px;
}

.AppLayout .ant-layout-sider-collapsed .ant-menu-title-content:not(.ant-popover-inner-content *) {
  display: none;
}

.AppLayout .headbar .ant-col {
  display: flex;
  align-items: center;
}

.ant-layout {
  background: var(--app-entry-layout-bg);
}

.exchanges-page .ant-tabs-nav {
  margin-bottom: 0;
}

.exchanges-page .ant-tabs-tab,
.ant-tabs-tab {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #6b7280;
}

.exchanges-page .ant-tabs-tab:not(:first-child) {
  margin-left: 40px;
}

.collaborators-page .ant-col {
  display: flex;
  align-items: center;
}

.collaborator-page .ant-col {
  display: flex;
  align-items: center;
}

.ant-popover-inner {
  border-radius: 6px;
}

.ant-modal-content {
  border-radius: 6px;
}

.ant-modal-header {
  border-radius: 6px;
}

.ant-drawer-close {
  margin-top: 10px;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 24px 0;
}

.ant-drawer-body .ant-tabs-nav {
  margin-bottom: 10px;
}

.ant-drawer-body .ant-tabs-nav-list {
  justify-content: space-between;
  width: 100%;
}

.ant-drawer-body .ant-tabs-tab {
  justify-content: center;
  width: 100%;
  padding: 4px 0;
}

.ant-drawer-body .ant-tabs-tab:nth-last-child(2) {
  margin-right: 0;
}

.user-popover {
  border-right: 0;
}

.ant-popover-arrow {
  display: none;
}

.popconfirm-show-arrow > .ant-popover-content > .ant-popover-arrow {
  display: unset;
}

.tfa-modal .ant-modal-body {
  padding: 0;
}

.ant-skeleton-header {
  padding-right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.new-audit-modal .ant-modal {
  top: 4%;
  width: 94% !important;
  padding: 0;
  margin: auto;
}

.new-audit-modal .ant-modal-body {
  padding: 40px;
}

.new-audit-modal .ant-modal-content {
  min-height: 93vh;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
}

.findings-popover .ant-popover-title {
  min-width: 150px;
}

.ant-switch-small {
  min-width: 35.5px;
  width: 35.5px;
  height: 17px;
  line-height: 17px;
  font-size: 14px;
}

.ant-switch-small .ant-switch-handle {
  position: absolute;
  top: 1.5px;
  left: 2px;
  width: 13px;
  height: 13px;
  transition: all 0.2s ease-in-out;
}

.ant-switch-small.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 13px - 2px);
}

/* options */
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background: var(--gray-200);
}

/* * * * * * * * * * * * * * * * 
   * Antd Table Customizations  *
   * * * * * * * * * * * * * * * */
/* Customize bg color of selected row in table */
.ant-table-tbody > tr.ant-table-row-selected > td {
  background: var(--gray-200) !important;
}

.ant-table-tbody > tr.ant-table-row:hover > td {
  background: #f8f8f8;
}

.ant-table-tbody > tr.ant-table-row-selected:hover > td {
  background: var(--gray-300) !important;
}

.ant-pagination-item-link-icon {
  color: var(--ck2);
}

/* Customize table filter */
.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected {
  background-color: var(--gray-200);
  color: var(--gray-800);
}

.ant-table-filter-trigger.active {
  color: var(--ck2);
}

.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active {
  color: var(--ck2);
}

/* * * * * * * * * * * * * * * * 
 * Antd Button Customizations  *
 * * * * * * * * * * * * * * * */

/* Primary (filled) buttons (type="primary") */
.ant-btn-primary {
  border-radius: 4px;
  background: var(--ck2);
  border-color: var(--ck2);
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  background: var(--red-400);
  border-color: var(--red-400);
}

.ant-btn-primary:active {
  background: var(--red-700);
  border-color: var(--red-700);
}

/* Link buttons (type="link") */
.ant-btn-link {
  color: var(--gray-800);
}

.ant-btn-link:hover,
.ant-btn-link:focus {
  color: var(--ck2);
}

/* Default (outlined) buttons (type="default" OR not specified) */
.ant-btn:not(.ant-btn-primary):not(.ant-btn-link):not(.ant-btn-text):not(.ant-dropdown-trigger):not(
    .ant-btn-iconOnly
  ) {
  color: var(--ck2);
  border-color: var(--ck2);
}

/* Pagination */

.ant-pagination .ant-pagination-next,
.ant-pagination .ant-pagination-prev {
  width: 32px;
  min-width: 32px;
}

.ant-pagination-item,
.ant-pagination-item-link,
.ant-pagination-next,
.ant-pagination-prev {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: none;
  flex-direction: column;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: var(--gray-800);
  fill: var(--gray-800);
  background: none;
}

.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link {
  border: none;
  background: none;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link,
.ant-pagination-jump-next .ant-pagination-item-link,
.ant-pagination-jump-prev .ant-pagination-item-link {
  border: none;
  background: none;
  display: inline-flex;
  height: 32px;
  width: 32px;
}

.ant-pagination.ant-pagination-mini .ant-pagination-jump-prev,
.ant-pagination.ant-pagination-mini .ant-pagination-jump-next,
.ant-pagination.ant-pagination-mini .ant-pagination-item-link {
  height: 24px;
  width: 24px;
  min-width: 24px;
}

.ant-pagination-item .ant-pagination-item-link {
  display: flex;
}

.ant-pagination-prev:not(.ant-pagination-disabled):hover .ant-pagination-item-link,
.ant-pagination-next:not(.ant-pagination-disabled):hover .ant-pagination-item-link,
.ant-pagination-jump-prev:not(.ant-pagination-disabled):hover .ant-pagination-item-link,
.ant-pagination-jump-next:not(.ant-pagination-disabled):hover .ant-pagination-item-link {
  /* border: none; */
  background: var(--gray-100);
  color: var(--gray-800);
}

.ant-pagination-item,
.ant-pagination-jump-next,
.ant-pagination-jump-prev,
.ant-pagination-prev,
.ant-pagination-next {
  margin-right: 4px;
}

.ant-pagination-item:hover a {
  color: inherit;
}

.ant-pagination-item:not(.ant-pagination-item-active):hover,
.ant-pagination.ant-pagination-mini .ant-pagination-item:not(.ant-pagination-item-active):hover {
  color: var(--gray-800);
  background: var(--gray-100);
}

.ant-pagination-item-active,
.ant-pagination-item-active:hover {
  color: var(--gray-800);
  background: var(--gray-200) !important;
}

.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
  color: var(--gray-800);
}

.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis .anticon,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis .anticon {
  transform: rotate(90deg) translateY(2px);
}

.ant-pagination-item
  .ant-pagination-item-link
  .ant-pagination-item-container
  .ant-pagination-item-ellipsis
  .anticon {
  transform: rotate(90deg);
  color: var(--gray-700);
}

.ant-pagination-item:hover
  .ant-pagination-item-link
  .ant-pagination-item-container
  .ant-pagination-item-ellipsis
  .anticon {
  animation: 1.25s ease-in-out infinite fade;
}

@keyframes fade {
  0% {
    opacity: 100%;
  }
  50% {
    opacity: 50%;
  }
  100% {
    opacity: 100%;
  }
}

.ant-pagination-options {
  margin-left: 0;
}

.ant-pagination-options .ant-select-item {
  padding-left: 8px;
}

.ant-pagination-options .ant-select-single .ant-select-selector {
  padding-left: 8px;
  padding-right: 43px;
}

.ant-pagination-options .ant-select-arrow {
  top: Calc(50%);
  right: 20.83px;
  margin-top: 0;
  height: 8.33px;
}

.ant-pagination-options .ant-select-sm .ant-select-arrow {
  right: 9.835px;
}

.ant-pagination-options .ant-pagination-options-size-changer,
.ant-pagination-options .ant-pagination-options-size-changer .ant-select-item,
.ant-pagination-options
  .ant-pagination-options-size-changer
  .ant-select-selector
  .ant-select-selection-item {
  font-weight: 500;
  color: var(--gray-800);
  font-size: 13px;
}

.ant-pagination-options .ant-pagination-options-size-changer .ant-select-selector {
  background-color: transparent;
  border: none;
}

.ant-pagination-options
  .ant-pagination-options-size-changer
  .ant-select-selector
  .ant-select-selection-item {
  padding-right: 0;
}

/* Notice */
.ant-message-notice-content {
  border-radius: 4px;
  min-width: 362px;
  text-align: left;
  height: 64px;
  padding: 0 16px 0 0;
}

.ant-message-custom-content {
  display: grid;
  grid-template-columns: 64px 1fr;
  height: 100%;
  align-items: center;
}

.ant-message .anticon {
  position: static;
  top: auto;
  margin-right: 0px;
  display: flex;
  justify-content: center;
}

.ant-notification-notice {
  border-radius: 4px;
  padding: 0px;
  min-height: 80px;
  min-width: 442px;
}

.ant-notification-notice-icon {
  position: initial;
  margin: 0px;
  display: block;
  height: 100%;
}

.ant-notification-notice-content {
  min-height: 80px;
}

.ant-notification-notice-with-icon {
  display: grid;
  grid-template-columns: 64px 1fr;
  grid-template-rows: 1fr;
  min-height: 80px;
  align-items: center;
}

.ant-notification-notice-description,
.ant-notification-notice-message {
  grid-column-start: 2;
  grid-column-end: 2;
}

.ant-notification-notice-with-icon .ant-notification-notice-message {
  margin-left: 0px;
  margin-bottom: 0px;
}

.ant-switch-checked {
  background-color: var(--ck2);
}

.ant-click-animating-node {
  -webkit-box-shadow: 0 0 0 0 var(--ck2) !important;
  box-shadow: 0 0 0 0 var(--ck2) !important;
}

/* * * * * * * * * * * * * * * * 
   * Antd Input Customizations  *
   * * * * * * * * * * * * * * * */

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: var(--gray-5);
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused,
.ant-input-affix-wrapper-focused:hover {
  border-color: var(--gray-800) !important;
  box-shadow: 0px 0px 4px rgba(66, 66, 66, 0.75) !important;
}

.ant-input:hover,
.ant-input:not(.ant-input-disabled):hover {
  border-color: var(--gray-5);
}

.ant-input:focus,
.ant-input-focused,
.ant-input-focused:hover {
  border-color: var(--gray-800) !important;
  box-shadow: 0px 0px 4px rgba(66, 66, 66, 0.75) !important;
}

/* TEXTAREA */
textarea.ant-input {
  line-height: 20px;
}

/* * * * * * * * * * * * * * * * 
  * Antd Select Customizations  *
  * * * * * * * * * * * * * * * */
.ant-select:not(.ant-select-customize-input) .ant-select-selector:hover,
.ant-select:hover {
  border-color: var(--gray-5);
}

.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-open .ant-select-selector {
  border-color: var(--gray-800) !important;
  box-shadow: 0px 0px 4px rgba(66, 66, 66, 0.75) !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled)
  .ant-select-item-option-state {
  color: var(--ck2);
}

/* * * * * * * * * * * * * * * * 
  * Antd Picker Customizations  *
  * * * * * * * * * * * * * * * */
.ant-picker:hover {
  border-color: var(--gray-5);
}

.ant-picker-focused,
.ant-picker:focus,
.ant-picker:active {
  border-color: var(--gray-800) !important;
  box-shadow: 0px 0px 4px rgba(66, 66, 66, 0.75) !important;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner .ant-input-number:hover {
  border-color: var(--gray-5);
  color: #fff;
  background: var(--ck2);
}

.ant-picker-today-btn {
  color: var(--ck2);
}

.ant-picker-today-btn:hover {
  color: var(--ck2);
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid var(--ck2);
}

/* * * * * * * * * * * * * * * * 
  * Antd Input Number Customizations  *
  * * * * * * * * * * * * * * * */
.ant-input-number:hover {
  border-color: var(--gray-5);
}

.ant-input-number-focused,
.ant-input-number:focus,
.ant-input-number:active {
  border-color: var(--gray-800) !important;
  box-shadow: 0px 0px 4px rgba(66, 66, 66, 0.75) !important;
}

.ant-input-number-handler:hover .ant-input-number-handler-up-inner,
.ant-input-number-handler:hover .ant-input-number-handler-down-inner {
  color: var(--ck2);
}

/* * * * * * * * * * * * * * * * 
  * Antd Form Item Customizations  *
  * * * * * * * * * * * * * * * */
.ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled):not(
    .ant-input-affix-wrapper-borderless
  ).ant-input-affix-wrapper,
.ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover,
.ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled):not(
    .ant-input-affix-wrapper-borderless
  ).ant-input-affix-wrapper:hover {
  background-color: #fff;
  border-color: var(--gray-5);
}

/* * * * * * * * * * * * * * * * 
  * Antd checker Item Customizations  *
  * * * * * * * * * * * * * * * */
.ant-checkbox-inner {
  height: 15px;
  width: 15px;
  border-width: 1px;
}

.ant-checkbox-inner:not(.ant-checkbox-disabled) {
  border-color: var(--gray-900);
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--ck2);
  border-color: var(--ck2) !important;
}

.ant-checkbox-disabled:not(.ant-checkbox-checked) .ant-checkbox-inner {
  border-color: var(--gray-900) !important;
  background: #ffffff;
}

.ant-checkbox-disabled .ant-checkbox-inner::after {
  border-color: white !important;
}

.ant-checkbox-checked::after {
  border: 1px solid var(--ck2);
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: var(--ck2);
}

.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: var(--gray-100);
}

/* * * * * * * * * * * * * * * * 
   * Antd Dropdown Customizations  *
   * * * * * * * * * * * * * * * */
/* Drop down trigger buttons (<Dropdown />) */
.ant-dropdown-trigger {
  color: var(--gray-800);
}

.ant-dropdown-trigger:hover {
  border-color: var(--gray-5) !important;
  color: var(--gray-800);
}

.ant-dropdown-trigger:focus {
  border-color: var(--gray-800) !important;
  box-shadow: 0px 0px 4px rgba(66, 66, 66, 0.75) !important;
  color: var(--gray-800);
}

.ant-tabs-ink-bar {
  background: var(--ck2) !important;
}

.ant-tabs-tab > .ant-tabs-tab-btn {
  color: var(--gray-800);
}

.ant-tabs-tab-active > .ant-tabs-tab-btn {
  color: var(--ck2) !important;
}

/* please do not overwrite global antd css for specific components */

/* .ant-radio-group {
  display: flex;
  flex-direction: row;
  gap: 4px;
}
.ant-radio-button-wrapper:first-child {
  border-left: none;
  border-radius: 4px;
}
.ant-radio-button-wrapper:last-child {
  border-radius: 4px;
}
.ant-radio-button-wrapper {
  color: #3676cc;
  border: none;
  border-left: none;
  border-right: none;
  background-color: rgba(55, 101, 161, 0.05);
  padding: 12px 2px 2px 12px;
  border-radius: 4px;
}
.ant-radio-button-wrapper:not(:first-child)::before {
  visibility: hidden;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background-color: #3676cc;
} */
/* 
.anticon {
  vertical-align: 0px !important;
}

.ant-tag-close-icon {
  vertical-align: 2px !important;
}

*/

.ant-spin-dot-item {
  background-color: var(--ck2);
}
